import React, { memo, useMemo } from 'react';

import MessageContentWrapper from './MessageContentWrapper';
import ErrorBoundary from '@components/ErrorBoundary';
import { useMessageContext } from '@contexts/MessageContext';
import { IErrand, IMessage, IUserData } from '@interfaces/Conversation';
import MessageContentSkeleton from './Skeletons/MessageContentSkeleton';
import TwinAvatar from '@components/TwinAvatar';
import { useAvatarContext } from '@contexts/avatar';

/*
 *  This component renders a message in the conversation. This includes not only the chat bubble, but the Author and
 * time sent as well
 *
 *  This component has the following properties:
 *    - author - The name of the person that composed the message
 *    - time - Time the message was sent/recieved
 *    - message - The actual content of the message
 *    - isOperator - A boolean value telling us if the author was an operator or not ,
 *     this will decide if the message is rendered on the left or right of the screen and
 *     in the appropriate
 */
type TConversationMessageProps = {
  errand: IErrand;
  index: number;
  showDate: boolean;
  showSentiment: boolean;
};

const ConversationMessage = memo(({ errand, index, showDate, showSentiment }: TConversationMessageProps) => {
  const { isPrivate } = useMessageContext();
  const { checkIfCurrentMessageCanRenderAvatar } = useAvatarContext();

  const { privateMessages, messages } = errand;

  const messageBuffer = useMemo((): IMessage[] => {
    return isPrivate ? privateMessages : messages;
  }, [isPrivate, messages, privateMessages]);

  const isLastMessage = useMemo((): boolean => !messageBuffer?.[index + 1], [messageBuffer, index]);
  const message = useMemo((): IMessage => messageBuffer?.[index], [messageBuffer, index]);

  const messageType = useMemo((): string => message?.messageType, [message]);
  const messageId = useMemo((): string => message?._id, [message]);
  const messageSender = useMemo((): IUserData => message?.sender, [message]);

  const messageSenderId = useMemo((): string => messageSender?._id, [messageSender]);

  const renderAvatar = useMemo((): boolean => {
    return checkIfCurrentMessageCanRenderAvatar(messageId, isLastMessage, messageType, messageSenderId);
  }, [isLastMessage, messageType, messageId, messageSenderId, checkIfCurrentMessageCanRenderAvatar]);

  return (
    <ErrorBoundary debug={`./src/Components/ConversationMessage.tsx`}>
      <MessageContentWrapper
        errand={errand}
        index={index}
        message={message}
        showDate={showDate}
        showSentiment={showSentiment}
      >
        {renderAvatar && <TwinAvatar messageId={message?._id} />}
        <MessageContentSkeleton errand={errand} index={index} message={message} />
      </MessageContentWrapper>
    </ErrorBoundary>
  );
});

export default ConversationMessage;
