/**
 * @file PointsTracker.tsx
 * @description Component which displays user's points balance
 * @author Eric Velepucha
 */

import useInitialMount from '@common/hooks/useInitialMount';
import axiosCall from '@services/axios';
import { getUserId } from '@storage/userStorage';
import React, { useCallback, useEffect } from 'react';
import Styles from './PointsTrackerStyles.module.css';
import { useTranslation } from 'react-i18next';
import { AngelPointsIcon } from '@assets/Icons';
import { useErrandContext } from '@contexts/ErrandContext';
import { MorphType } from '@common/MorphType';
import { useSocketContext } from '@contexts/socket';
import { adjustPointsBalance, selectPointsBalance, setPointsBalance } from '@slices/pointsSlice';
import { useAppDispatch, useAppSelector } from '@common/hooks/reduxHooks';
import { useRootContext } from '@contexts/RootContext';
import { useUserContext } from '@contexts/user';

const PointsTracker = () => {
  // Points tracker should display the user's total points
  // It will be a permanent fixture in ConversationTitle component
  // Initial render will request Core endpoint for user's points
  // Event listeners should pick up point events
  // which will trigger the dislayed points to update
  const { t } = useTranslation();
  const { setMorphType } = useErrandContext();
  const { eventsSocket } = useSocketContext();
  const { _id } = useUserContext();
  const pointsBalance = useAppSelector(selectPointsBalance);
  const dispatch = useAppDispatch();
  const { returnConsentGiven, handleShakingConsent } = useRootContext();

  // Request core (which fetches points from Sunsoft)
  const fetchPoints = useCallback(async () => {
    const req = { url: `points/${_id}` };
    return await axiosCall(req);
  }, [_id]);

  // Runs on initial component mount
  const initFunction = useCallback(async () => {
    const { balance } = await fetchPoints();
    dispatch(setPointsBalance(balance));
  }, [dispatch, fetchPoints]);

  // Opens the points table
  const handleClick = () => {
    if (!returnConsentGiven) {
      handleShakingConsent();
      return;
    }
    setMorphType(MorphType.PointsTable);
  };

  // handler function for the adjust-points-balance event
  const handleAdjustPointsBalanceEvent = useCallback(
    (payload: number) => {
      dispatch(adjustPointsBalance(payload));
    },
    [dispatch]
  );

  // Sets the points balance on first render
  useInitialMount(initFunction);

  // Listen for event to update points state
  useEffect(() => {
    const socketEvent = eventsSocket.current;
    socketEvent?.on('adjust-points-balance', handleAdjustPointsBalanceEvent);

    // Clean up event listener on unmount
    return () => {
      socketEvent?.off('adjust-points-balance', handleAdjustPointsBalanceEvent);
    };
  }, [eventsSocket, handleAdjustPointsBalanceEvent]);

  // Set the points balance whenever the user._id changes
  useEffect(() => {
    initFunction();
  }, [_id, initFunction]);

  return (
    <button className={Styles.wrapper} onClick={handleClick}>
      <AngelPointsIcon className={Styles.icon} />
      <span className={Styles.numberText}>{pointsBalance.toLocaleString()}</span>
    </button>
  );
};

export default PointsTracker;
