import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState, useMemo, lazy, Suspense } from 'react';
import { useSpeechRecognition } from 'react-speech-recognition';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { RootContext, useRootContext } from '@contexts/RootContext';
import { FooterContext } from '@contexts/FooterContext';
import { containsUrl } from '@common/containsUrl';
import { getUserPlayedLast24Hours, getWindowLocationPath } from '@storage/userStorage';
import { Mic, InviteFriendFilled, EnterOTPIcon } from '@assets/Icons';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import PulseIcon from './PulseIcon';
import axiosCall from '@services/axios';
import ActionControls from '@components/ActionControls';
import AudioRecorder from '@components/AudioRecorder';
import AttachmentMenuCloseButton from '@components/AttachmentMenuCloseButton';
import ChatFooterModal from '@components/ChatFooterModal';
import ChatInputField from '@components/ChatInputField';
import ChatSendButton from '@components/ChatSendButton';
import FormConsentContent from '@components/FormConsentContent';
import containsVideo from '@common/containsVideo.js';
import ConversationFooterStyle, { FooterIcon, CFDivider, ConversationRussellStyle, ConversationRightOfInputStyle } from '@styles/ConversationFooterStyles';
import eventBus from '@common/eventBus.js';
import EmojiSelector from '@components/EmojiSelector';
import ForgotPasswordButton from '@components/ForgotPasswordButton';
import FileSelector from '@components/FileSelector';
import HoneyPot from '@components/HoneyPot';
import LangPicker from '@components/LanguagePickerButton';
import LanguagePickerCarousel from '@components/LanguagePickerCarousel';
import LanguageUtils from '@common/LanguageUtils.js';
import MultiSelectField from '@components/MultiSelectField';
import PublicInternalToggle from '@components/PublicInternalToggle';
import Timeline from '@components/Timeline';
import TypingIndicator from '@components/TypingIndicator';
import ResendOTPButton from './ResendOTPButton';
import { useErrandContext } from '@contexts/ErrandContext';
import { useCustomLinkContext } from '@contexts/CustomLinkContext';
import { FooterActionIcon } from './FooterActionIcon';
import { getCurrentParticipant, insertReturnConsentField } from '@common/errandUtils';
import { insertField } from '@common/hooks/useUpdateField';
import { sendWorkflow } from '@common/WorkflowsUtils';
import MorphContext from '@contexts/MorphContext';
import { shouldCurrUserReplyTo } from '@common/userMessagesUtils';
import { MorphType } from '@common/MorphType';
import { PaperClipNotepad } from '@assets/Icons';
import FooterInput from './FooterInputStorage/storage';
import { ElectronicSignatureEventType, FormBodySizeType, PaymentActionStateType } from '../Forms/commonForms';
import { UAParser } from 'ua-parser-js';
import ThinClientUtils from '@common/ThinClientUtils';
import { containsOnlyDigits, qidSeparatorString } from '@common/StringUtils';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { MorganTheme, Snackbar, useTheme } from '@mui/material';
import { Sentiments } from '@mTypes/TSentiment';
import { ChatType } from '@common/ChatType';
import { CReqState, isActionPasswordRelated } from '@common/msgUtils';
import SelectAllBorrowersButton from '@components/SelectAllBorrowersButton';
import { TBorrower } from '@mTypes/TBorrower';
import { IErrand, IUserData, IMessage, IUserChatAction } from '@interfaces/Conversation';
import useWindowDimensions from '@common/hooks/useWindowDimensions';
import LeftButtonMorphLoanProducts from './ChooseLoanProduct/LeftButtonMorphLoanProducts';
import { LoanProduct } from '@mTypes/TChooseLoanProducts';
import { ValidatorFunctions } from '@common/Validators';
import useInitialMount from '@common/hooks/useInitialMount';
import { UserPromptsMenuState } from './MorphUserPromptsMenu/types';
import { currentTimezones, getTimezoneIndex } from '@common/getTimezoneAbbrev';
import { VideoListMenuState } from './MorphVideoListMenu/types';
import { ElementPlus, SongPlayerPlay } from '@assets/Icons/index';
import UPM_TranslationModule, { TLang } from './MorphUserPromptsMenu/translations';
import useAbortController from '@common/hooks/useAbortController';
import TypingAnimation from './FooterTypingAnimation/TypingAnimation';
import { FooterTypingAnimationWrapper } from './FooterTypingAnimation/FooterTypingAnimationWrapper';
import { AccessType } from '@common/AccessType';
import { useCaptchaContext } from '@contexts/captcha';
import { useUserContext } from '@contexts/user';
import { useSocketContext } from '@contexts/socket';
import { Color as WalletColor, Event as WalletEvent, Page as WalletPage } from '@components/MorphWallet/MorphWalletType';
import { shouldPreventPlaySlotMachine } from '@common/SlotMachineUtils';
import { useAppContext } from '@contexts/AppContext';
import ReloadBanner from './ReloadBanner/ReloadBanner';
import { ResetFooterUserAction } from '@common/common';
import ConsentBox from '@components/ConsentBox';
import { isMobile } from '@common/deviceTypeHelper';
import { insertConsentField } from '@common/errandUtils';

import MultipleFooterTypingAnimations from './FooterTypingAnimation/MultipleTypingFooterAnimations';
import { useAppDispatch, useAppSelector } from '@common/hooks/reduxHooks';
import { updateInputValue } from '@slices/inputSlice';
import { attemptedPassword, selectIsPasswordValid } from '@slices/passwordSlice';
import { WORKFLOW_NAMES } from '@constants/WorkflowNames';
import { ANGEL_SIGN_FIELD_ATTRIBUTE } from '@common/angelSignUtils';
import useSwipeableBooleanData from '@common/hooks/useSwipeableBooleanData';

// Lazy load components
const MorphedConversationFooter = lazy(() => import('./MorphedConversationFooter'));

/*
 *  This component renders the footer at the bottom of the conversations page. It includes the
 *  The message input box, as well as the icons and the container that holds them all
 *
 *  This component does not currently take in any properties
 *  @todo Deprecate the fieldAttribute state value.
 *  @todo Fix type issues. 
 *
 */
type TConversationFooterProps = {
  dispFilterMode: string;
  editMessageId: string;
  errand: IErrand;
  operatorData?: IUserData;
  setDispFilterMode: Dispatch<SetStateAction<string>>;
  setEditMessageId: Dispatch<SetStateAction<string>>;
  setPreviewUrl: Dispatch<SetStateAction<string>>;
  setValue: Dispatch<SetStateAction<string>>;
  showSentiment: boolean;
  triggerSlotMachine: (arg0: any, arg1: any) => void;
  value: string;
};

const ConversationFooter = ({
  dispFilterMode, editMessageId, operatorData, setDispFilterMode, setEditMessageId, 
  setPreviewUrl, setValue, showSentiment, triggerSlotMachine, value
}: TConversationFooterProps) => {
  const theme: MorganTheme = useTheme();
  const {
    setMorphType,
    morphType,
    setIsMorphedFooterCloseButtonOnLeft,
    setMessageOptionsIndex,
    replyToRef,
    footerInputRef,
    welcomeUserMessageState,
    newFormEvent,
    formBodySize,
    setWelcomeUserMessageState,
    isPopupOpen,
    setIsPopupOpen,
    userPromptsMenuState,
    wetInitial,
    wetSignature,
    videoListMenuState,
    setPaymentActionState,
    errandId,
    morphIndent,
    setPhotoSelectorIndex,
    multipleFooterTypingAnimations,
    setMainPhoto,
    setPhotoLimit,
    mainPhoto,
    messageFilter,
    errand,
    isMorphedFooterCloseButtonOnLeft,
    triggeredSlotMachine,
    swipeableBooleanData,
  } = useErrandContext();

  const {
    isWidget,
    setErrands,
    userSelectedPromptRef,
    handleActionWorkflow,
    userSelectedVideoRef,
    morphedId,
    rootMorphType,
    selectedIndex,
    videoMenuTitle,
    conversationFooterRef,
    returnConsentGiven,
    setReturnConsentGiven,
    consentChecked,
    clickedDisabledFeatures,
    setClickedDisabledFeatures,
    handleShakingConsent
  } = useRootContext();

  const { isDesktop } = useWindowDimensions();

  const { shareCustomLinkInfoRef, shareCustomLinkMethod } = useCustomLinkContext();

  const { t, i18n } = useTranslation();
  const { _id, isOperator, isUser, tpConsentGiven } = useUserContext();
  const { addSentMessage } = useCaptchaContext();
  const { eventsSocket, messagesSocket, isEventsConnected, isMessagesConnected } = useSocketContext();
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [fieldAttribute, setFieldAttribute] = useState(null);
  const [iconToShow, setIconToShow] = useState('');
  const [isPublic, setIsPublic] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isTyping, setIsTyping] = useState([]);
  const [placeholder, setPlaceholder] = useState<string>(t('Type here...'));
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploadingDocs, setIsUploadingDocs] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [userMicOff, setUserMicOff] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPermissionReminder, setShowPermissionReminder] = useState(false);
  const [showContactsConsent, setShowContactsConsent] = useState(false)
  const [honeyPot, setHoneyPot] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const chatInputFieldRef = useRef(null);
  const inputContainerRef = useRef(null);
  const multiSelectFieldRef = useRef(null);
  const sendButtonRef = useRef(null);
  const textInputEnabled = useRef(true);
  const selectedAddress = useRef<string>('');
  const selectedFilesLengthRef = useRef(0);
  const [isWarningActive, setIsWarningActive] = useState<String | null>(null);
  const [sentimentPreview, setSentimentPreview] = useState<Sentiments>(Sentiments.Unvailable);
  const [handleOtpError, setHandleOtpError] = useState(false);
  const [isRussellPeters, setIsRussellPeters] = useState(false);
  const [showRussellPeters, setShowRussellPeters] = useState(false);
  const [showRPContainer, setShowRPContainer] = useState(false);
  const rphead1 = process.env.REACT_APP_MORGAN_CDN + '/Images/rphead1.png';
  const rphead2 = process.env.REACT_APP_MORGAN_CDN + '/Images/rphead2.png';
  const rphead3 = process.env.REACT_APP_MORGAN_CDN + '/Images/rphead3.png';
  const rphead4 = process.env.REACT_APP_MORGAN_CDN + '/Images/rphead4.png';
  const [randomPeekabooImage, setRandomPeekabooImage] = useState(rphead3);
  const dispatch = useAppDispatch();
  const isPasswordValid = useAppSelector(selectIsPasswordValid)

  const abortController = useAbortController();
  // used for representing the curr state of 'send message' request
  // see CReqState for more info
  const msgReqStateRef = useRef(new CReqState());

  const downloadBannerTimeoutsRef = useRef([]);

  const participant = getCurrentParticipant(errand, _id);

  // canSendText is used to keep track if the user is typing a phone number in the
  // input field instead of a name. If they enter a phone number, we can text
  // an invitation to that number even if the number is not in their phone book
  const canSendText = useRef(false);
  // when the user types while the contacts list is open, the filterName will update, this will
  // be used to filter through the contacts list when the axios call is made
  const [filterName, setFilterName] = useState('')
  // Set the default selectedContact to be empty, this will be passed in useContext to other components
  
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedBorrowers, setSelectedBorrowers] = useState<Set<TBorrower>>(new Set());
  const [borrowerList, setBorrowerList] = useState<Array<TBorrower>>([]);

  const [loanProducts, setLoanProducts] = useState<Array<LoanProduct>>([])
  const [hoveredLoanProduct, setHoveredLoanProduct] = useState<LoanProduct>(null);
  const [chosenLoanProduct, setChosenLoanProduct] = useState<LoanProduct>(null)

  const [selectedPrices, setSelectedPrices] = useState({})
  const [hideCalendarMonth, setHideCalendarMonth] = useState(!returnConsentGiven);
  const [hideTime, setHideTime] = useState(!returnConsentGiven);
  const [isInvalidWorkshopDate, setIsInvalidWorkshopDate] = useState(false);
  const [bannerAppeared, setBannerAppeared] = useState(0);

  const [justClickedCancel, setJustClickedCancel] = useState(false);

  const needToShowFormConsentRef = useRef<boolean>(true);
  const [selectedTimezoneIndex, setSelectedTimezoneIndex] = useState(getTimezoneIndex(currentTimezones()))
  const appContext = useAppContext();

  const prevLastMessageIdRef = useRef(null);
  const [attachmentTabText, setAttachmentTabText] = useState('opt')

  // page open in MorphWallet
  const activeWalletPage = useRef<WalletPage>(null);

  const senderType: string = isOperator ? 'Operator' : 'User';

  // only when multipleFooterTypingAnimations is null 
  // (meaning there is no on-going multiple footer animation)
  const renderFooterTypingAnimationWrapper = useMemo(
    () =>
      (multipleFooterTypingAnimations === null &&
        morphType === MorphType.VideoListMenu &&
        videoListMenuState === VideoListMenuState.VIDEO_SELECTED) ||
      [MorphType.CreditRepairWelcome, MorphType.RefinanceCalculatorWelcome].includes(morphType),
    [morphType, multipleFooterTypingAnimations, videoListMenuState]
  );

  const animatedText = useMemo(
    () =>
      morphType === MorphType.VideoListMenu
        ? `${t('VideoListMenuSendToStart')} ${videoMenuTitle}`
        : morphType === MorphType.CreditRepairWelcome
        ? t('helpMeRepairMyCredit')
        : morphType === MorphType.RefinanceCalculatorWelcome
        ? t('refinanceCalculatorWelcomeMessage')
        : '',
    [morphType, t, videoMenuTitle]
  );

  const russellPetersImg = useMemo(() => {
    return(
      <div className={isDesktop === true ? 'russellContainer' : 'russellContainerMobile'}>
      <img
          className={showRussellPeters ? 'showRussellPeters' : 'hideRussellPeters'}
          src={randomPeekabooImage}
          alt='Russell Peters'
        ></img>
      </div>
    )
  }, [isDesktop, randomPeekabooImage, showRussellPeters]);

  const welcomeUserMessageInView = useMemo(
    () => welcomeUserMessageState?.welcomeUserMessage?.inView,
    [welcomeUserMessageState?.welcomeUserMessage?.inView]
  );

  const fieldAttributeDescription = useMemo(() => fieldAttribute?.description, [fieldAttribute?.description]);

  const errandAction = useMemo(() => errand?.action, [errand?.action]);
  const errandRecipients = useMemo(() => errand?.recipients, [errand?.recipients]);
  const errandIcon = useMemo(() => errand?.icon, [errand?.icon]);
  const errandWorkflow = useMemo(() => errand?.workflow, [errand?.workflow]);
  const errandParticipants = useMemo(() => errand?.participants, [errand?.participants]);
  const errandDisplayName = useMemo(() => errand?.displayName, [errand?.displayName]);
  const errandPlaceholder = useMemo(() => errand?.placeholder, [errand?.placeholder]);
  const errandLastMessageData = useMemo(() => errand?.lastMessageData, [errand?.lastMessageData]);
  const errandCreatedAt = useMemo(() => errand?.createdAt, [errand?.createdAt]);
  const errandUpdatedAt = useMemo(() => errand?.updatedAt, [errand?.updatedAt]);
  const errandMessages = useMemo(() => errand?.messages, [errand?.messages]);
  const errandType = useMemo(() => errand?.type, [errand?.type]);
  const errandActiveContext = useMemo(() => errand?.activeContext, [errand?.activeContext]);
  const errandParentId = useMemo(() => errand?.parentId || '', [errand?.parentId]);

  const userActionId = useMemo(() => errandAction?.userActionId, [errandAction?.userActionId]);

  const selectedFilesLength = useMemo(() => selectedFiles?.length, [selectedFiles?.length]);

  const lastMessageAction = useMemo(() => errandLastMessageData?.action, [errandLastMessageData?.action]);
  const lastMessageType = useMemo(() => errandLastMessageData?.messageType, [errandLastMessageData?.messageType]);
  const lastMessageUserAction = useMemo(() => errandLastMessageData?.userAction, [errandLastMessageData?.userAction]);

  // These are the morphtypes which won't show the tpConsent or the returnConsent
  // due to their design and how clunky it would look to have the consent
  // squished in with it
  const morphTypesThatHideConsent = useMemo(() => [
    MorphType.UserPromptsMenu, 
    MorphType.VideoListMenu, 
    MorphType.CreditRepairDisputeAccountType,
    MorphType.Wallet,
    MorphType.PhotoPlain,
    MorphType.PhotoMain,
    MorphType.Payment,
    MorphType.BorrowerSelector,
    MorphType.LoanProducts,
    MorphType.LoanProductPriceTable,
    MorphType.SelectMultiple,
    MorphType.SwipeableBoolean,
  ].includes(morphType), [morphType]);

  // Used for mobile view footer password edge case view
  const isCurrActionRelatedToPassword = useMemo(
    () => isActionPasswordRelated(errandAction?.action ?? errandAction),
    [errandAction]
  );

  /**
   * Footer hide condition, we can hide/show footer with this logic
   * @returns true/false
   * true - hide footer
   * false - show footer
   */
  const footerHideCondition = useMemo(() => {
    if (errandType === ChatType.Form) return false;

    if (!participant) return true;
    if (!Array.isArray(errandMessages)) return true;
    return false;
  }, [errandMessages, errandType, participant]);

  const clearDownloadBannerTimeouts = useCallback(() => {
    const timeoutsArrRef = downloadBannerTimeoutsRef.current;
    // no timeouts to clear out
    if(timeoutsArrRef.length === 0) return; 
    // clear the arr
    const tmArr = timeoutsArrRef.splice(0,timeoutsArrRef.length)
    // clear each timeout id
    for(const tmID of tmArr) clearTimeout(tmID);
  }, []);

  const resetStates = useCallback(() => {
    /* States that are exclusive to the user screen */
    setErrands((prev) => {
      const chatObj = prev.find((e) => e._id === errandId);

      if (chatObj) {
        ResetFooterUserAction(chatObj);
      }

      return [...prev];
    });
    setValue('');
    chatInputFieldRef.current?.update('');
    sendButtonRef.current?.update('');
    textInputEnabled.current = true;
    setFieldAttribute(null);
    setIsSending(false);
    setIsRecording(false);
    setSentimentPreview(Sentiments.Unvailable);
    setMorphType((prev) => {
      if (prev === MorphType.PrivateChat) return prev;
      return MorphType.None;
    });
    setIsMorphedFooterCloseButtonOnLeft(false);
    setMessageOptionsIndex(-1);
    replyToRef.current = { chat: errandId, originalMessage: '', originalText: '' };
  }, [
    replyToRef,
    setErrands,
    setMorphType,
    errandId,  
    setIsMorphedFooterCloseButtonOnLeft, 
    setMessageOptionsIndex, 
    setValue,
    setIsSending,
    setIsRecording,
    setSentimentPreview
  ])

  const submitMessageEdit = useCallback(async (overrideValue?: string) => {
    let newMsgValue = chatInputFieldRef.current?.formattedValue;
    // this is only used for DROPDOWN message edit edge case, 
    // because there is an empty string in chatInputFieldRef.current?.formattedValue
    // when user edits the dropdown message and clicks send.
    // This fixes it.
    if (overrideValue) {
      newMsgValue = overrideValue;
    }
    if (await containsUrl(newMsgValue)) {
      newMsgValue = await containsUrl(newMsgValue);
    }
    const request = {
      url: `chat/${errandId}/message/${editMessageId}`,
      method: 'put',
      data: { message: newMsgValue },
    };

    try {
      let response = await axiosCall(request);
      console.log(`Edit sent: `, response);
    } catch (error: any) {
      console.error(`Edit Error: ${error.message}`);
      return resetStates();
    }
    setIsSending(false);

    /* These are only present on the User's view, ignore if we are on the console page */
    if (isUser) {
      setFieldAttribute(null);
      setErrands((prev) => {
        const chatObj = prev.find((e) => e._id === errandId);

        if (chatObj) {
          ResetFooterUserAction(chatObj);
        }

        return [...prev];
      });
    }

    // Exit the editing state
    setEditMessageId('');
    return resetStates();
  }, [editMessageId, errandId, isUser, resetStates, setEditMessageId, setErrands]);

  // Use this function to reset morph settings anytime it isn't being used anymore
  const resetMorph = useCallback(() => {
    setMorphType((prev) => {
      if (prev === MorphType.PrivateChat) return prev;
      return MorphType.None;
    });
    console.log("Morph settings reset!")
  }, [setMorphType]);

  /**
   * After sending a message the response data provides the _id and this function
   * will add that message identifier to the beginning of the messages array.
   * This will ensure that when AngelAi reads the message and the notification
   * read event is recieved by the client the message id exists to attach the 
   * new notfications array to.
   * 
   * This process will guarantee that double ticks are shown, even on slower networks
   *
   * @param {Object} response - The response object containing the new message ID.
   */
  const addMessageIdToMessagesArray = useCallback((response) => {
    if (!response || !response._id) return;
    setErrands((prev) => {
      const chatObj = prev.find((e) => e._id === errandId);

      if (chatObj) {
        let messageIndex = chatObj.messages.findIndex((e) => e._id === response._id);
        if (messageIndex !== -1) return prev;
        if (chatObj?.recipients?.length > 0) {
          chatObj.privateMessages = [ ...(chatObj.privateMessages || []), { _id: response._id, sender: {}, notifications: [] } as IMessage];
        } else {
          chatObj.messages = [ ...chatObj.messages, { _id: response._id, sender: {}, notifications: [] } as IMessage];
        }
      }


      return [...prev];
    });
  }, [errandId, setErrands]);

  // Close the PrivateChat Morphed Footer
  // const handleClosePrivateChat = useCallback(() => {
  //     setErrands((prev) => {
  //         const chatObj = prev.find((e) => e._id === errandId);

  //         if (chatObj) {
  //           chatObj.recipients = [];
  //         }

  //         return [...prev];
  //     });
  // }, [errandId, setErrands])

  // Close the contacts list by resetting morph, clearing input, and unselecting
  // the contact if any are chosen
  const handleCloseContacts = useCallback(() => {
    setSelectedContact(null)
    resetMorph()
    resetStates()
    canSendText.current = false
  }, [resetMorph, resetStates]);

  // When the user clicks on the button to add a friend on Thin Client, open the contacts list
  const handleOpenContacts = useCallback(() => {
    resetStates() // clear the input so that it doesn't interfere with search filter
    setMorphType(MorphType.Contacts)
    setFilterName('')
    setIsRecording(false)
    setErrands((prev) => {
      const chatObj = prev.find((e) => e._id === errandId);

      if (chatObj) {
        ResetFooterUserAction(chatObj, 'Type a name or number...');
      }

      return [...prev];
    });
  }, [errandId, resetStates, setErrands, setMorphType]);

  const handleEmoji = useCallback((event, emojiObject) => {
    sendButtonRef.current?.update(
      `${chatInputFieldRef.current?.unformattedValue}${emojiObject.emoji}`
    );
    chatInputFieldRef.current?.update(
      `${chatInputFieldRef.current?.unformattedValue}${emojiObject.emoji}`
    );
  }, []);;

  const handleAttachmentAction = useCallback(async () => {
    setMorphType((prev) => {
      if (prev === MorphType.PrivateChat) return prev;
      return MorphType.None;
    });

    //we are first going to upload to s3 and return document IDs.
    const formData = new FormData();

    //we will use the convention of pushing the main photo to the
    //top of this list, which avoids any schema changes etc.
    if (mainPhoto !== undefined && selectedFiles[mainPhoto]?.name) {
      let file = selectedFiles[mainPhoto];
      formData.append('files', file, file.name);
    }
    for (const key in selectedFiles) {
      if (key !== mainPhoto?.toString() && selectedFiles[key]?.name) {
        formData.append('files', selectedFiles[key], selectedFiles[key].name);
      }
    }

    formData.append('recipients', errandRecipients?.sort()?.join(',') || '');
    formData.append(
      'accessType',
      !isPublic ? AccessType.internal : morphType === MorphType.PrivateChat ? AccessType.private : AccessType.public
    );
    formData.append('user', _id);
    formData.append('userType', senderType);
    formData.append('fieldDocument', 'true');

    const docParams = {
      url: `chat/${errandId}/document`,
      method: 'POST',
      data: formData,
    };
    //the IDs of the documents.
    let docs = [];
    try {
      docs = await axiosCall(docParams);
    } catch (error) {
      console.error(error);
    }
    return docs;
  }, [_id, errandRecipients, errandId, isPublic, mainPhoto, morphType, selectedFiles, senderType, setMorphType]);

  // This function handles whether we are showing the calendar date picker
  const handleShowCalendar = useCallback(() => {
    if (isUser && (!tpConsentGiven || !returnConsentGiven)){
      handleShakingConsent();
      return;
    };
    setHideCalendarMonth((prev) => {
      return !prev
    })
  }, [isUser, tpConsentGiven, returnConsentGiven, handleShakingConsent]);

  // This function handles whether we are showing the time picker
  const handleShowTime = useCallback(() => {
    if (isUser && (!tpConsentGiven || !returnConsentGiven)){
      handleShakingConsent();
      return;
    };
    setHideTime((prev) => {
      return !prev
    })
  }, [isUser, tpConsentGiven, returnConsentGiven, handleShakingConsent]);

  const handleBooleanAction = useCallback(
    async (bool) => {
      const preparedBoolValue = bool ? 'Yes' : 'No';

      // if user is currently editing a message:
      // if this line fires, then it  means that user has started editing a message of type boolean
      // that has Yes/No buttons.
      // The logic below handles the editing logic for Yes/No boolean answers.
      if (editMessageId) {
        return submitMessageEdit(preparedBoolValue);
      }

      const data: any = {
        sender: _id,
        senderType: senderType,
        message: preparedBoolValue,
        messageType: 'Field',
        userAction: userActionId,
        intendedAudience: errandRecipients?.join(',') || '',
        accessType: !isPublic
          ? AccessType.internal
          : morphType === MorphType.PrivateChat
          ? AccessType.private
          : AccessType.public,
      };

      if (honeyPot) {
        data.a_password = honeyPot;
      }

      const payload = { url: `chat/${errandId}/message`, method: 'POST', data: data };

      /* Message is sent here! */

      try {
        let response = await axiosCall(payload);
        addMessageIdToMessagesArray(response);
        console.log(`Boolean sent: `, response);
        if (!tpConsentGiven){
          insertConsentField(errandId, _id);
          eventBus.dispatch('consentGiven');
        }
        if (!returnConsentGiven){
          setReturnConsentGiven(true);
          insertReturnConsentField(errandId, _id);
          // Commenting out so that returnConsent is asked again if page refreshes
          // sessionStorage.setItem('returnConsent', 'true');
          eventBus.dispatch('consentGiven');
          setHideCalendarMonth(false);
          setHideTime(false);
        }
      } catch (error) {
        console.error(error);
      }

      return resetStates();
    },
    [
      _id,
      addMessageIdToMessagesArray,
      editMessageId,
      userActionId,
      errandRecipients,
      errandId,
      isPublic,
      morphType,
      honeyPot,
      resetStates,
      senderType,
      submitMessageEdit,
      returnConsentGiven,
      setReturnConsentGiven,
      tpConsentGiven,
    ]
  );

  const handleConfirmFieldAttributeAction = useCallback(async () => {
    const message = 'Done';

    const data = {
      sender: _id,
      senderType: senderType,
      message: message,
      messageType: 'Field',
      userAction: userActionId,
      intendedAudience: errandRecipients?.join(',') || '',
      accessType: !isPublic
        ? AccessType.internal
        : morphType === MorphType.PrivateChat
        ? AccessType.private
        : AccessType.public,
    };

    const payload = { url: `chat/${errandId}/message`, method: 'POST', data };

    try {
      let response = await axiosCall(payload);
      addMessageIdToMessagesArray(response);
      console.log(`Confirm field attribute action sent: `, response);
    } catch (error) {
      console.error(error);
    }
    return resetStates();
  }, [
    _id,
    addMessageIdToMessagesArray,
    userActionId,
    errandRecipients,
    errandId,
    isPublic,
    morphType,
    resetStates,
    senderType,
  ]);

  /* Triggered by clicking the x button that appears in the input bar at the bottom of the screen
     When the input is in an action state */
  const cancelAction = useCallback(async (key, clear = false, withMorphTypeChange = true) => {
    // show consent notification if needed
      if(withMorphTypeChange === true) {
        setMorphType((prev) => {
          if (prev === MorphType.PrivateChat) return prev;
          return MorphType.None;
        });
      }
      setHideCalendarMonth(false);
      setHideTime(false);
      setSelectedFiles([]);
      setIsUploadingDocs(false);
      setSelectedBorrowers(new Set())
      // If the userAction was in progress then we need to update it to
      // 9/10/2024 - Dennis, Do not do anything with the user action in the backend, let the AI handle it.
      /*if (key === undefined && errandAction?.status === 'in-progress') {
        await axiosCall({
          url: `useraction/${userActionId}`,
          method: 'put',
          data: {
            status: 'suspended',
          },
        });
      }*/

      setErrands((prev) => {
        const chatObj = prev.find((e) => e._id === errandId);

        if (chatObj) {
          ResetFooterUserAction(chatObj);
          
          // force re-render
          return [...prev];
        }

        // no re-render
        return prev;
      });

      setFieldAttribute(null);
      // cancel message editing
      setEditMessageId('');
      setIsMorphedFooterCloseButtonOnLeft(false);
      setMessageOptionsIndex(-1);
      replyToRef.current = { chat: errandId, originalMessage: '', originalText: '' };

      if (clear) {
        setValue('');
        chatInputFieldRef.current?.update('');
        footerInputRef?.current?.focus();
      } else {
        let newVal =
          fieldAttributeDescription === 'OTP' 
          ? key :
          !key || (key?.length > 1)
            ? chatInputFieldRef.current?.unformattedValue
            : chatInputFieldRef.current?.unformattedValue + key;
        chatInputFieldRef.current?.update(newVal || '');
  
        //Added this observer to detect if the fallback/default input has
        //rendered after the action is cancelled, and set cursor to the end of the field
        const observer = new MutationObserver(() => {
          let input = footerInputRef?.current as HTMLInputElement;
          if (input) {
            input.focus();
            input.setSelectionRange(input.value.length, input.value.length);
            observer.disconnect();
          }
        });
        observer.observe(inputContainerRef.current || document, {
          subtree: false,
          childList: true,
        });
      }
  },[
    footerInputRef,
    replyToRef,
    setEditMessageId,
    setIsMorphedFooterCloseButtonOnLeft,
    setMessageOptionsIndex,
    setValue,
    errandId, 
    setMorphType,
    setErrands,
    fieldAttributeDescription,
  ]);

  const submitFileUpload = useCallback(async (id) => {
    const formData = new FormData();
    for (let i = 0; i < selectedFilesLength; i++) {
      if (selectedFiles[i]){
        formData.append('files', selectedFiles[i], selectedFiles[i].name);
      }
    }
    formData.append('recipients', errandRecipients?.sort()?.join(',') || '');
    formData.append('accessType', !isPublic ? AccessType.internal : morphType === MorphType.PrivateChat ? AccessType.private : AccessType.public);
    formData.append('user', id);
    formData.append('userType', senderType);
    const docParams = {
      url: `chat/${errandId}/document`,
      method: 'POST',
      data: formData,
    };

    let sendDocuments;
    try {
      sendDocuments = await axiosCall(docParams);
    } catch (error) {
      console.error(error);
    }

    console.log('Documents sent: ', sendDocuments);
    setSelectedFiles([]);
    setIsUploadingDocs(false);
    return resetStates();
  }, [errandRecipients, errandId, isPublic, morphType, resetStates, selectedFiles, senderType, selectedFilesLength]);

  const processWelcomeInputValue = useCallback((numValue: string) => {
    // Attempt to convert the input to a number
    const parsedNum = (typeof numValue === 'string' && containsOnlyDigits(numValue.trim())) ? parseFloat(numValue.trim()) : numValue;

    // Check if the parsed value is a valid number
    if (typeof parsedNum !== 'number' || isNaN(parsedNum)) {
      return false; // Return false if not a valid number
    }

    // Check if the number is in the range of 1 to 6 (inclusive)
    if (parsedNum >= 1 && parsedNum <= 6) {
      setWelcomeUserMessageState((prevState) => ({
          ...prevState,
          welcomeUserMessage: {
            ...prevState.welcomeUserMessage,
            userChosenHintIndex: parsedNum
          }
      }));

      return true;
    }
    
    return false;
  }, [setWelcomeUserMessageState]);

  // keep track of which page is open in MorphWallet
  const handleWalletPageChange = (e: any) => {
    if (e?.type !== WalletEvent.PageChange) {
      return;
    }

    const { activePage } = e?.detail;

    activeWalletPage.current = activePage;

    // clear the input box
    chatInputFieldRef.current?.update('');

    // if on a page which requires use of the input box, focus on the input box
    if (activePage === WalletPage.AngelPointsFungibleExchange) {
      footerInputRef?.current?.focus();
    }
  }

  // on morph type change
  useEffect(() => {
    if (morphType === MorphType.Wallet) {
      // listen for page changes in MorphWallet
      window.addEventListener(WalletEvent.PageChange, handleWalletPageChange);
    } else {
      // stop listening for wallet events
      window.removeEventListener(WalletEvent.PageChange, handleWalletPageChange);

      // reset wallet variables
      activeWalletPage.current = null;
    }
  }, [morphType]);

  const handleSubmit = useCallback(async (e) => {
    e?.preventDefault();
    if(morphType === MorphType.NewPassword && !isPasswordValid){
      dispatch(attemptedPassword())
    }

    if (fieldAttributeDescription === 'MORPH CREDIT REPAIR DISPUTE ACCOUNT TYPE' && userSelectedPromptRef.current === null) {
      return;
    }

    footerInputRef?.current?.focus();

    if (!textInputEnabled.current){
      chatInputFieldRef.current?.update('');
    }

    if (isUser && !consentChecked) return;

    if (isUser && morphType !== MorphType.VideoListMenu && morphType !== MorphType.UserPromptsMenu && !tpConsentGiven){
      insertConsentField(errandId, _id);
      eventBus.dispatch('consentGiven');
    }

    if (isUser && morphType !== MorphType.VideoListMenu && morphType !== MorphType.UserPromptsMenu && !returnConsentGiven && !morphTypesThatHideConsent){
      setReturnConsentGiven(true);
      insertReturnConsentField(errandId, _id);
      // Commenting out so that returnConsent is asked again if page refreshes
      // sessionStorage.setItem('returnConsent', 'true');
      eventBus.dispatch('consentGiven');
      setHideCalendarMonth(false);
      setHideTime(false);
      if (morphType === MorphType.Time){
        return;
      }
    }

    // consent for chat type form
    if (isUser && errandType === ChatType.Form) {
      if (needToShowFormConsentRef.current) {
        return eventBus.dispatch('showFormConsent');
      }

      if (formBodySize !== FormBodySizeType.Small) {
        newFormEvent(ElectronicSignatureEventType.ArrowUp);
      }
    }
    // Reset justClickedCancel (used for FormOpen's cancel button)
    setJustClickedCancel(false);

    // If our honeypot has been triggered as a borrower, don't do anything
    if (honeyPot) {
      return;
    }

    //no sending of text if we're making a payment
    if (morphType === MorphType.Payment){
      return;
    }

    //only allow user to send doc field if files are attached
    if (fieldAttribute) {
      if (['DOCUMENT', 'PHOTO', 'MAINPHOTO', 'SKIPPABLE PHOTO'].includes(fieldAttributeDescription) && selectedFilesLength === 0) {
        console.log('handleSubmit: there are no files attached to the action');
        return;
      }
      // do not allow an address to be sent if it was not selected from part of google autocomplete.
      // we do 'startsWith' so users can enter apartment numbers as well.
      // REQUEST - removed at Dennis' request
      // if (fieldAttributeDescription === 'ADDRESS' && (!chatInputFieldRef.current?.formattedValue?.startsWith(selectedAddress.current) || !selectedAddress.current)) {
      //   return
      // }
    }

    // Track that a message was sent (for spam reasons)
    addSentMessage(isOperator);

    const isCustomRollerInputValue = fieldAttributeDescription === 'DROPDOWN' && multiSelectFieldRef.current?.isCustomUserValue === true;
    // This allows the dropdown values to be submitted since the dropdown overrides the ChatInputField component and that leaves the chatInputFieldRef null as is the default
    let inputValue =
      fieldAttributeDescription === 'DROPDOWN'
        ? `${multiSelectFieldRef.current?.getValue?.description} ${qidSeparatorString} ${multiSelectFieldRef.current?.getValue?.data}`
        : fieldAttributeDescription === 'MORPH CREDIT REPAIR DISPUTE ACCOUNT TYPE' ? userSelectedPromptRef.current
        : chatInputFieldRef.current?.formattedValue;
    if(
      (welcomeUserMessageInView === true)
      && !isOperator && !errandAction) {
      // check inputValue to be 1 -> 6
      const success = processWelcomeInputValue(inputValue);
      // if processed, do nothing
      if(success) {
        chatInputFieldRef.current?.update('');
        return;
      }
    }

    chatInputFieldRef.current?.update(''); //fix the appearance that we've cached the input
    if (isSending) {
      console.log('handleSubmit: in the process of submitting a message.');
      return;
    }
    // below if statements means that if there is no text, file, audio then there is nothing to send
    if (inputValue === '' && selectedFilesLength === 0 && !isRecording &&
      // if there is a selected contact, we'll want to send that contact's name, so check this too
      !selectedContact && !selectedBorrowers.size) {
      console.log('handleSubmit: there is nothing to submit.');
      setHandleOtpError(true);
      return;
    }

    // send to the wallet instead of the AngelAi chat
    if (morphType === MorphType.Wallet) {
      // send the input value to MorphWallet
      window.dispatchEvent(new CustomEvent(WalletEvent.ChatSend, {
        detail: {
          input: inputValue
        }
      }));
      return;
    }

    // if there are transcripts or audio then submit whatever is available
    if (isRecording) {
      setIsRecording(false);
      resetMorph()
      return;
    }

    if (morphType === MorphType.CalendarMonth && isInvalidWorkshopDate){
      console.log('handleSubmit: An invalid workshop date was submitted.')
      return;
    }

    setIsSending(true);

    // upload documents
    //docId will be set if the attachment is
    //in response to a fieldAttribute
    let docIds;
    if ((selectedFilesLength > 0) && fieldAttribute !== null) {
      //if we are looking at the main photo selector, then we need to 
      // make sure that there was a main photo selected.
      if (morphType === MorphType.PhotoMain && mainPhoto === null) {setIsWarningActive(t('pleaseSelectValidMainPhoto')); setIsSending(false);return;}
      docIds = await handleAttachmentAction();
    }
    else if (selectedFilesLength > 0) {
      // upload documents, normal attachment
      await submitFileUpload(_id);
      // only return if there is no message value
      if (inputValue === '') {
        return;
      }
    }
    setMorphType((prev) => {
      if (prev === MorphType.PrivateChat) return prev;
      return MorphType.None;
    });

    /* If the user is editing a message, update it instead of sending a new message */
    if (editMessageId && editMessageId) {
      // check if the editing message is a dropdown
      if (fieldAttributeDescription === 'DROPDOWN') {
        // take input from dropDown because chatInputValue will be ''
        return submitMessageEdit(inputValue);
      }
      return submitMessageEdit();
    }

    /* Stores our payload */
    let data;
    /* If there is an action being interacted with, and we are on the user side,
        send the message as a field Type */
    if (fieldAttribute !== null) {
      if (isUser && errandAction?.action?.description === 'Full Name') {
        let prev = errandWorkflow;

        if (prev !== undefined) {
          (async (prev) => {
            // Load the correct workflow, first use search then filter to ensure
            let wfs = await axiosCall({
              url: `workflow/db/search?active=true&fields=_id,name`,
              method: 'post',
              data: {
                search: prev,
              },
            });
            let wf = wfs.filter((w) => w.name === prev);

            // If we have something after the filter send it!
            if (wf.length > 0) {
              console.log('Sending workflow', wf[0]);
              await axiosCall({
                url: `chat/${errandId}/workflow/${wf[0]?._id}`,
                method: 'POST',
                data: {
                  userId: _id,
                  userType: 'User',
                  owner: _id,
                },
              });
            }
          })(prev);
        }
      }
      if(isCustomRollerInputValue === true) {
        data = {
          sender: _id,
          senderType: senderType,
          intendedAudience: errandRecipients?.join(',') || '',
          accessType: !isPublic ? AccessType.internal : morphType === MorphType.PrivateChat ? AccessType.private : AccessType.public,
          message: multiSelectFieldRef.current?.getValue?.description,
          messageType: 'Text',
        };
      } else {
        data = {
          sender: _id,
          senderType: senderType,
          intendedAudience: errandRecipients?.join(',') || '',
          accessType: !isPublic ? AccessType.internal : morphType === MorphType.PrivateChat ? AccessType.private : AccessType.public,
          message: inputValue,
          documents: docIds || undefined,
          messageType: 'Field',
          userAction: userActionId,
        };
      }
    } else if (await containsVideo(inputValue /*, operatorData*/)) {
      // If a youtube video/ID is found in the message, it is logged in
      // the database as a Video messageType
      data = {
        sender: _id,
        senderType: senderType,
        intendedAudience: errandRecipients?.join(',') || '',
        accessType: !isPublic ? AccessType.internal : morphType === MorphType.PrivateChat ? AccessType.private : AccessType.public,
        message: inputValue,
        messageType: 'Video',
      };
    } else if (await containsUrl(inputValue)) {
      let formattedMessage = await containsUrl(inputValue);
      data = {
        sender: _id,
        senderType: senderType,
        intendedAudience: errandRecipients?.join(',') || '',
        accessType: !isPublic ? AccessType.internal : morphType === MorphType.PrivateChat ? AccessType.private : AccessType.public,
        message: formattedMessage,
        messageType: 'Url',
      };
    } else if (morphType === MorphType.SelectMultiple) {
        data = {
        sender: _id,
        senderType: senderType,
        intendedAudience: errand?.recipients?.join(',') || '',
        accessType: !isPublic ? AccessType.internal : AccessType.public,
        message: chatInputFieldRef.current?.unformattedValue,
        messageType: 'Url',
      };
    } else {
      data = {
        sender: _id,
        senderType: senderType,
        intendedAudience: errandRecipients?.join(',') || '',
        accessType: !isPublic ? AccessType.internal : morphType === MorphType.PrivateChat ? AccessType.private : AccessType.public,
        message: inputValue,
        messageType: 'Text',
      };
    }

    // TODO Clean up.
    const recipients = [_id];

    // If there's a selected contact, send that contact's name as a message and send an invitation
    if (selectedContact) {
      let justNumbers;
      let contactEmail;
      if (selectedContact.phoneNumbers && selectedContact.phoneNumbers.length) {
        justNumbers = selectedContact.phoneNumbers[0].number.replace(/[^0-9]+/g, '');
      }
      if (selectedContact.emailAddresses && selectedContact.emailAddresses.length) {
        contactEmail = selectedContact.emailAddresses[0].email;
      }
      const firstAndLastName = `${selectedContact.givenName} ${selectedContact.familyName}`;

      // do Post requests to set the fields needed to complete invite friend workflow without asking
      // the questions in the workflow. This will let the user send the invite immediately if they
      // press enter or submit the selected contact
      const body = {
        contactFirstName: selectedContact.givenName,
        contactLastName: selectedContact.familyName,
        contactFullName: firstAndLastName,
        contactEmail: contactEmail,
        contactPhone: justNumbers,
        chatId: errandId,
        userId: _id,
      };
      const payload = {
        url: `contact/insertInviteFields`,
        method: 'POST',
        data: body,
      };
      await axiosCall(payload);

      // data = {
      //   sender: _id,
      //   senderType: senderType,
      //   intendedAudience: recipients?.filter((userId) => userId !== id).join(','),
      //   accessType: AccessType.public,
      //   message: selectedContact?.displayName,
      //   messageType: 'Text',
      // };
      // Trigger the Thin Client's Invite Contact workflow. All the needed fields are set when user selects a contact. Dialer should appear immediately.
      await sendWorkflow('', WORKFLOW_NAMES.INVITE_CONTACT, errandId, recipients, AccessType.public, _id, isOperator, null);
      handleCloseContacts();
      return resetStates();
    }
    // If user is responding to 'chooseBorrowers' action field, send msg
    // of type 'Field' and include the selectedBorrowers array after the
    // <separator/> tag in the message
    if(MorphType.BorrowerSelector && selectedBorrowers.size){
      setMorphType(MorphType.None);
      // await insertField('chooseBorrowers', Array.from(selectedBorrowers), errand);

      // const resolveAction = async () => {
      //   // sets the corresponding slot machine useraction status to resolved
      //   const payload = {
      //     url: `useraction/${errandAction?.userActionId}`,
      //     method: 'put',
      //     data: {
      //       value: selectedBorrowers,
      //       status: 'resolved',
      //     },
      //   };
      //   await axiosCall(payload);
      // };

      data = {
        sender: _id,
        senderType: senderType,
        message: `${Array.from(selectedBorrowers)
          .map((borrower) => `${borrower.firstName} ${borrower.lastName}`)
          .join(', ')}
          <separator/>
          ${JSON.stringify(Array.from(selectedBorrowers))}`,
        accessType: AccessType.public,
        messageType: 'Field',
        userAction: userActionId
      };
      const payload = { url: `chat/${errandId}/message`, method: 'POST', data: data };
      try {
        // mark current message post request as sent
        msgReqStateRef.current.sent();
        let response = await axiosCall(payload);
        addMessageIdToMessagesArray(response);
        // once response is received, mark as finished
        msgReqStateRef.current.finished();
        console.log(`Message sent: `, response);
      } catch (error: any) {
        // mark current message post request to have errors
        msgReqStateRef.current.finished(error as Error);
        console.error(`Message Error: ${error.message}`);
      }
      setSelectedBorrowers(new Set());
      // await resolveAction();
      return resetStates();
    }

    // If user submits a time or date, we should close the morphType
    if (morphType === MorphType.CalendarMonth || morphType === MorphType.Time || morphType === MorphType.DOB){
      resetStates();
    }
    
    // If we can send a text, insert the provided phone number as the friend's phone number and email,
    // and then trigger the invite friend workflow. Morgan will ask for the friend's name and then will send the invite.
    if (canSendText.current) {
      const justNumbers = inputValue.replace(/[^0-9]+/g, '')
      const insertPhoneFields = async () => {
        insertField('invitePhoneNumber', justNumbers, errand, undefined, _id); // change fieldName to invitePhoneMorph when MRGN-496 is implemented
        insertField('inviteEmailAddress', 'placeholder@email.com', errand, undefined, _id); //using placeholder email for now for testing text. Can remove after MRGN-496.
      };
      await insertPhoneFields();
      await sendWorkflow('', WORKFLOW_NAMES.INVITE_FRIEND, errandId, recipients, AccessType.public, _id, isOperator, null);
      handleCloseContacts()
      // normally, we would want to also remove the saved invite fields after the invitation is sent. However, this is already 
      // implemented in MRGN-496. So for now, just test inviting 1 friend per conversation or delete manually with mongodb compass.
    }

    if (honeyPot) {
      data.a_password = honeyPot;
    }

    if (replyToRef.current.originalMessage) {
      data.replyTo = replyToRef.current;

      replyToRef.current = { chat: errandId, originalMessage: '', originalText: '' };
    }
    const payload = { url: `chat/${errandId}/message`, method: 'POST', data: data };

    /* Message is sent here!
     *
     * Moved logic applied to response into the try catch to
     * handle errors and prevent crashing if message post request
     * does not succeed
     *
     */
    try {
      // mark current message post request as sent
      msgReqStateRef.current.sent();
      let response = await axiosCall(payload);
      addMessageIdToMessagesArray(response);
      // once response is received, mark as finished
      msgReqStateRef.current.finished();
      console.log(`Message sent: `, response);
    } catch (error: any) {
      // mark current message post request to have errors
      msgReqStateRef.current.finished(error as Error);
      console.error(`Message Error: ${error.message}`);
    }
    // if custom roller input value
    // cancel curr action
    if(isCustomRollerInputValue === true) {
      // first cancel current action, then send message in chat as a free message
      await cancelAction(undefined, false, true);
    }
    dispatch(updateInputValue(''))
    setSelectedFiles([])
    return resetStates();
  }, [
    _id,
    fieldAttributeDescription,
    addMessageIdToMessagesArray,
    addSentMessage,
    cancelAction,
    consentChecked,
    editMessageId,
    errand,
    errandId,
    fieldAttribute,
    footerInputRef,
    formBodySize,
    handleAttachmentAction,
    handleCloseContacts,
    honeyPot,
    isInvalidWorkshopDate,
    isOperator,
    isPublic,
    isRecording,
    isSending,
    isUser,
    mainPhoto,
    morphType,
    newFormEvent,
    processWelcomeInputValue,
    replyToRef,
    resetMorph,
    resetStates,
    returnConsentGiven,
    selectedBorrowers,
    selectedContact,
    selectedFilesLength,
    senderType,
    setMorphType,
    submitFileUpload,
    submitMessageEdit,
    errandAction,
    errandRecipients,
    errandWorkflow,
    userActionId,
    errandType,
    t,
    tpConsentGiven,
    userSelectedPromptRef,
    welcomeUserMessageInView,
    setReturnConsentGiven,
  ]);

  const handleMorphClose = useCallback(() => {
    setErrands((prev) => {
      const chatObj = prev.find((e) => e._id === errandId);

      if (chatObj) {
        ResetFooterUserAction(chatObj);

        chatObj.recipients = [];
      }

      // reset message reply cache
      replyToRef.current = { chat: errandId, originalMessage: '', originalText: '' };

      // when morph closed restore chat preview
      chatObj.lastMessageData = chatObj.messages[chatObj.messages.length - 1];

      // set all re-render trigger at the same time
      setIsMorphedFooterCloseButtonOnLeft(false);
      setMorphType(MorphType.None);

      // Deep clone single errand
      return prev.map((x) => {
        if (x === chatObj) {
          return JSON.parse(JSON.stringify(x));
        } else {
          return x;
        }
      });
    });
  }, [
    errandId, 
    setIsMorphedFooterCloseButtonOnLeft,
    setMorphType,
    setErrands,
    replyToRef
  ]);

  const handlePopup = useCallback(() => {
    return (
      <Stack flexDirection='row' gap={1} sx={{ ml: 0.5, mr: 1 }}>
        <Button
          sx={{ color: 'var(--gray000)', textAlign: 'capitalize' }}
          variant='contained'
          //set input back to what it was previously
          onClick={() => {setIsPopupOpen(false); resetStates()}}
        >
          {t('tHide')}
        </Button>
      </Stack>
    );
  }, [resetStates, setIsPopupOpen, t]);

  const handleBoolean = useCallback(() => {
    if (errandAction === undefined || errandAction === null) {
      setFieldAttribute(() => null);
    }

    return (
      <Stack flexDirection='row' gap={1} sx={{ ml: 0.5, mr: 1 }} className='booleanStack'>
        <Button
          className='yesButton'
          variant='contained'
          onClick={() => handleBooleanAction(true)}
          onMouseDown={(e) => {e.preventDefault()}}
          disabled={!consentChecked && isUser}
        >
          <DoneIcon />
          {t('yes')}
        </Button>
        <Button 
          className='noButton'
          variant='contained'
          onClick={() => handleBooleanAction(false)}
          onMouseDown={(e) => {e.preventDefault()}}
          disabled={!consentChecked && isUser}
        >
          <CloseIcon />
          {t('no')}
        </Button>
      </Stack>
    );
  }, [errandAction, handleBooleanAction, t, consentChecked, isUser]);

  const {
    addOrEditAnswer,
    broadcastAnswer,
    getPlayingInitialAnimation,
    canGoForward,
  } = useSwipeableBooleanData();
  const [swipeableBooleanBtnsDisabled, setSwipeableBooleanBtnsDisabled] = useState(false);
  const handleSwipeableBoolean = useCallback(() => {
    if (errandAction === undefined || errandAction === null) {
      setFieldAttribute(() => null);
    }

    if (swipeableBooleanData === null) {
      return null;
    }

    const currentQuestionAnswer = swipeableBooleanData
      ?.answers[swipeableBooleanData?.currentQuestionIdx]
      ?.answer
      ?.toLowerCase();

    const buttonWidth = isMobile() ? '50px' : '61px';
    const makeButtonStyles = (backgroundColor: string = 'white') => ({
      height: '61px',
      marginTop: 0,
      minWidth: buttonWidth,
      width: buttonWidth,
      maxWidth: buttonWidth,
      border: '1px solid var(--orange700)',
      borderRadius: '8px',
      backgroundColor: backgroundColor,
      ...(backgroundColor !== 'white' ? { color: 'white' } : {}),
    });

    const buttonHoverStyles = {
      '&:hover': {
        opacity: 0.9,
      },
    };

    const yesButtonBgColor = currentQuestionAnswer === 'yes' ? 'var(--green850)' : 'white';
    const noButtonBgColor = currentQuestionAnswer === 'no' ? 'var(--blue850)' : 'white';

    const yesButtonStyles = {
      ...makeButtonStyles(currentQuestionAnswer === 'yes' ? 'var(--green850)' : 'white'),
      '&:hover': {
        ...buttonHoverStyles,
        backgroundColor: yesButtonBgColor,
      },
    };

    const noButtonStyles = {
      ...makeButtonStyles(currentQuestionAnswer === 'no' ? 'var(--blue850)' : 'white'),
      borderLeftWidth: '2px',
      '&:hover': {
        ...buttonHoverStyles,
        backgroundColor: noButtonBgColor,
        borderLeftWidth: '2px',
      },
    };

    const handleSwipeableBooleanSubmit = (answer: "Yes" | "No") => {
      const success = addOrEditAnswer(
        swipeableBooleanData.questions[swipeableBooleanData.currentQuestionIdx].question,
        answer,
        swipeableBooleanData.currentQuestionIdx,
        swipeableBooleanData.questions[swipeableBooleanData.currentQuestionIdx].qidCode,
      );
      if (success) {
        if (!canGoForward()) {
          // Prevent double-clicking
          setSwipeableBooleanBtnsDisabled(true);
          // In case sending the message (Core API) fails
          // and the user needs to click again to retry
          setTimeout(() => {
            setSwipeableBooleanBtnsDisabled(false);
          }, 2000);
        }
        broadcastAnswer(answer);
      }
    };

    return (
      <div style={{
        display: 'flex',
        gap: '16px',
        zIndex: 1000000,
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'stretch',
          margin: '-11px -5px -5px 0',
          backgroundColor: 'var(--orange700)',
          borderRadius: '5px',
          pointerEvents: getPlayingInitialAnimation() ? 'none' : 'unset',
        }}>
          <Button
            sx={noButtonStyles}
            variant='outlined'
            onClick={swipeableBooleanBtnsDisabled ? undefined : () => handleSwipeableBooleanSubmit("No")}
            onMouseDown={(e) => { e.preventDefault() }}
          >
            {t('no')}
          </Button>
          <Button
            sx={yesButtonStyles}
            variant='outlined'
            onClick={swipeableBooleanBtnsDisabled ? undefined : () => handleSwipeableBooleanSubmit("Yes")}
            onMouseDown={(e) => { e.preventDefault() }}
          >
            {t('yes')}
          </Button>
        </div>
      </div>
    );
  }, [t,
    errandAction,
    swipeableBooleanData,
    addOrEditAnswer,
    broadcastAnswer,
    getPlayingInitialAnimation,
  ]);

  const handleConfirmFieldAttribute = useCallback(() => {
    if (errandAction === undefined || errandAction === null) {
      setFieldAttribute(() => null);
    }

    return (
      <Stack flexDirection='row' gap={1} sx={{ ml: 0.5, mr: 1 }}>
        <Button
          sx={{ color: 'var(--gray000)', textAlign: 'capitalize' }}
          variant='contained'
          onClick={handleConfirmFieldAttributeAction}
        >
          {t('confirmButton')}
        </Button>
      </Stack>
    );
  }, [errandAction, handleConfirmFieldAttributeAction, t]);

  const handleDocument = useCallback(() => {
    return (
      <FileSelector
        selectedFilesLength={selectedFilesLength || 0}
        setSelectedFiles={setSelectedFiles}
        setIconToShow={setIconToShow}
        setShowPermissionReminder={setShowPermissionReminder}
        setShowContactsConsent={setShowContactsConsent}
        handleCloseContacts={handleCloseContacts}
        handleOpenContacts={handleOpenContacts}
        operatorData={operatorData}
    />
    );
  }, [handleCloseContacts, handleOpenContacts, operatorData, selectedFilesLength]);

  const handlePhoto = useCallback(() => {
    return (
      <FileSelector
        selectedFilesLength={selectedFilesLength || 0}
        setSelectedFiles={setSelectedFiles}
        setIconToShow={setIconToShow}
        setShowPermissionReminder={setShowPermissionReminder}
        setShowContactsConsent={setShowContactsConsent}
        handleCloseContacts={handleCloseContacts}
        handleOpenContacts={handleOpenContacts}
        operatorData={operatorData}
      />
    );
  }, [handleCloseContacts, handleOpenContacts, operatorData, selectedFilesLength]);

  /**
   * Run this click event to switch from email submission to phone submission in Invite Friend workflow
   * Clicking on "Phone" or "Email" button will submit this as a message to the conversation
   * from the user, which triggers a different workflow with CHECK_USE_PHONE_OR_EMAIL.
   */
  const handleChoosePhoneOrEmail = useCallback(async (phoneOrEmail) => {
    if (isUser && (!tpConsentGiven || !returnConsentGiven)) return;

    const data: any = {
      sender: _id,
      senderType: senderType,
      message: phoneOrEmail,
      messageType: 'Field',
      userAction: userActionId,
      intendedAudience: errandRecipients?.join(',') || '',
      accessType: !isPublic ? AccessType.internal : morphType === MorphType.PrivateChat ? AccessType.private : AccessType.public,
    };

    if (honeyPot) {
      data.a_password = honeyPot;
    }

    const payload = { url: `chat/${errandId}/message`, method: 'POST', data: data };

    /* Message is sent here! */
    try {
      let response = await axiosCall(payload);
      addMessageIdToMessagesArray(response);
      console.log('handleChoosePhoneOrEmail was successful: ' + response);
    } catch (error) {
      console.error('handleChoosePhoneOrEmail failed: ' + error);
    }
    return resetStates();
  }, [
    _id,
    addMessageIdToMessagesArray,
    userActionId,
    errandRecipients,
    errandId,
    honeyPot,
    isPublic,
    isUser,
    morphType,
    resetStates,
    returnConsentGiven,
    senderType,
    tpConsentGiven,
  ]);

  const handleMorphInviteFriend = useCallback((morphPhoneOrEmail) => {
    if (morphPhoneOrEmail === 'MORPH PHONE') {
      return (
        <Stack flexDirection="row" gap={1} sx={{ ml: 0.5, mr: 1 }}>
          <Button
            onClick={() => cancelAction(undefined, true)}
            variant="contained"
            sx={{ color: 'var(--gray000)', margin: '3px 0' }}
          >
            {t('inviteUserDialogCancel')}
          </Button>
          <Button
            onClick={() => handleChoosePhoneOrEmail(`${t('inviteUserSwitchToEmail')}`)}
            variant="outlined"
            sx={{ margin: '3px 0', minWidth: '75px' }}
          >
            {t('inviteUserDialogEmail')}
          </Button>
        </Stack>
      );
    } else if (morphPhoneOrEmail === 'MORPH EMAIL') {
      return (
        <Stack flexDirection="row" gap={1} sx={{ ml: 0.5, mr: 1 }}>
          <Button
            onClick={() => cancelAction(undefined, true)}
            variant="contained"
            sx={{ color: 'var(--gray000)', margin: '3px 0' }}
          >
            {t('inviteUserDialogCancel')}
          </Button>
          <Button
            onClick={() => handleChoosePhoneOrEmail(`${t('inviteUserSwitchToPhone')}`)}
            variant="outlined"
            sx={{ margin: '3px 0', minWidth: '75px' }}
          >
            {t('inviteUserDialogPhone')}
          </Button>
        </Stack>
      );
    }
  }, [cancelAction, handleChoosePhoneOrEmail, t]);

  const handleOTP = useCallback(() => {
    return (
      <FooterIcon>
          <PulseIcon
            icon1={<EnterOTPIcon width="28px" height="28px" />}
            icon2={<ResendOTPButton errand={errand} />}
          />
      </FooterIcon>
    )
  }, [errand]);

  const handleCalendar = useCallback(() => {
    return (
      <FooterIcon>
        <Button onClick={handleShowCalendar} variant="outlined" className={!returnConsentGiven && "disabled"}>
          {hideCalendarMonth ? t('tShow') : t('tHide')}
        </Button>
      </FooterIcon>
    );
  }, [handleShowCalendar, hideCalendarMonth, t]);

  const handleTime = useCallback(() => {
    return (
      <FooterIcon>
        <Button onClick={handleShowTime} variant='outlined' className={!returnConsentGiven && "disabled"}>
          {hideTime ? t('tShow') : t('tHide')}
        </Button>
      </FooterIcon>
    )
  }, [handleShowTime, hideTime, t]);

  const getUserPromptsMenuPlaceholder = useCallback(() => {
    if(userPromptsMenuState === UserPromptsMenuState.WORKFLOW_NOT_SELECTED) {
      return t("UserPromptsMenuStart");
    } else if(userPromptsMenuState === UserPromptsMenuState.WORKFLOW_SELECTED){
      return '';
    } else if (userPromptsMenuState === UserPromptsMenuState.WORKFLOW_FETCH_LOADING) {
      return t('UserPromptsMenuFetchLoading');
    }
    else {
      return t('UserPromptsMenuFetchError');
    }
  }, [userPromptsMenuState, t]);

  const getCreditRepairAccountTypePlaceholder = useCallback(() => {
    if(userPromptsMenuState === UserPromptsMenuState.WORKFLOW_NOT_SELECTED) {
      return t("creditRepairDisputeAccountTypeSelect");
    } else if(userPromptsMenuState === UserPromptsMenuState.WORKFLOW_SELECTED){
      return '';
    } else if (userPromptsMenuState === UserPromptsMenuState.WORKFLOW_FETCH_LOADING) {
      return t('creditRepairDisputeAccountTypeLoading');
    }
    else {
      return t('tError');
    }
  }, [userPromptsMenuState, t]);

  const getVideoListMenuPlaceholder = useCallback(() => {
    if(videoListMenuState === VideoListMenuState.VIDEO_FETCH_ERROR){
      return t('VideoListMenuFetchError');
    } else if (videoListMenuState === VideoListMenuState.VIDEO_FETCH_LOADING) {
      return t('VideoListMenuFetchLoading');
    } else {
      return '';
    }
  }, [videoListMenuState, t]);

  const triggerWelcomeUser = useCallback(async () => {
      await sendWorkflow('', WORKFLOW_NAMES.WELCOME_USER, errandId, [_id], AccessType.public, _id, isOperator, null);
  }, [_id, errandId, isOperator]);

  const handleDefaultCloseMorph = useCallback(
    (clickHandler = null, btnText = null) => {
      const btnClickHandler = clickHandler ?? resetMorph;
      const additionalHandler = (e) => {
        btnClickHandler(e);
        setTimeout(() => {
          sendButtonRef.current?.update('');
        }, 100);
        chatInputFieldRef.current.update('');
        if (['CreditRepairWelcome', 'RefinanceCalculatorWelcome'].includes(lastMessageType)) {
          triggerWelcomeUser();
        }
      };

      return (
        <FooterIcon>
          <Button size="small" variant="outlined" sx={{ textTransform: 'none' }} onClick={additionalHandler}>
            {btnText ?? t('cancelButton')}
          </Button>
        </FooterIcon>
      );
    },
    [triggerWelcomeUser, resetMorph, lastMessageType, t]
  );

  const handleFormSignatureSubmitButton = () => {
    if (!returnConsentGiven){
      handleShakingConsent();
      return;
    }
    newFormEvent(ElectronicSignatureEventType.GoToInsertSignatureView)
  }

  const handleDisplayActionIcon = useCallback(() => {
    /**
     * NOTE
     * A) when a handleClickEdit is called in MessageContentWrapper, 
     * errandAction gets changed to a structure that is different 
     * from the one that is being used in actionDialog B) edit click handler.
     * 
     * In option A) errandAction doesn't have nested attr "action"
     * While option B) does.
     * In other words A) errandAction.action is undefined (errandAction has everything needed)
     * while B) errandAction.action is defined and hass all the needed attributes
     * 
     * THUS, the following code resolves this difference.
     * 
     * TODO: resolve this discrepancy between different structures
     *  */
    // check if nested action is undefined
    // active is always at errandAction
    const activeRef = errandAction?.active;

    let actionRef = errandAction?.action;
    // if so, use first level action, else leave nested one.
    if (!actionRef) {
      actionRef = errandAction;
    }
    // use the calculated reference to access needed props
    const actionId = actionRef?._id;
    const animatedIcon = actionRef?.animatedIcon || "";
    return (
      <>
        {activeRef === true &&
          actionRef?.fieldName === 'password' &&
          isUser && (
            <ForgotPasswordButton
              errand={errand}
              setErrands={setErrands}
            />
          )}
          {/* Prevent cropped UI display to user on mobile OR widget view (cropped input box issue fix) */}
          {(isDesktop === true || (isDesktop === false && isCurrActionRelatedToPassword === false))
            && <FooterActionIcon
                actionId={actionId}
                base64Icon={errandIcon}
                _invertedBase64Icon={animatedIcon}
              />}
      </>
    )
  }, [errand, isCurrActionRelatedToPassword, isUser, setErrands, isDesktop, errandAction, errandIcon]);

  const renderLeftOfInput = useCallback(() => {
    if (isPopupOpen) return handlePopup();
    if (morphType === MorphType.FormInsertSignature && wetInitial && wetSignature) {
      if (!returnConsentGiven){
        setClickedDisabledFeatures(true);
      }

      return (
        <Button
          size="small"
          variant="outlined"
          sx={{ 
            cursor: !returnConsentGiven ? 'not-allowed' : 'pointer',
            color: 'var(--gray000)',
            textTransform: 'none',
            background: 'var(--green700)',
            '&:hover': {
              color: 'var(--green700)',
              borderColor: 'var(--green700)',
            }
          }}
          onClick={handleFormSignatureSubmitButton}
        >
          {t('loginFormSubmit')}
        </Button>
      );
    }

    if (morphType === MorphType.Edit) {
      return (
        <FooterIcon>
          <span style={{ fontSize: '0.9rem', fontWeight: '600' }}>
            {t('MorphEdit_EditingLabel')}
          </span>
        </FooterIcon>
      );
    } else if ([MorphType.UserPromptsMenu, MorphType.CreditRepairDisputeAccountType, MorphType.SelectMultiple].includes(morphType)) {
      return (
        <FooterIcon>
          <ElementPlus style={{ fontSize: '1rem'}} />
        </FooterIcon>
      );
    } else if (morphType === MorphType.VideoListMenu) {
      return (
        <FooterIcon style={{ border: '1px solid var(--orange700)', borderRadius: '10px' }}>
          <SongPlayerPlay style={{ height: '25px', width: '25px', margin: '0px 3px 0px 4px'}} />
        </FooterIcon>
      )
    } else if (fieldAttributeDescription === 'BOOLEAN' || fieldAttributeDescription === 'CONFIRM') {
      if (isDesktop) {
        return (
          <LangPicker
            isDesktop={isDesktop}
            setDispFilterMode={setDispFilterMode}
          />
        );
      } else {
        return (
          <LanguagePickerCarousel
            isDesktop={isDesktop}
            setDispFilterMode={setDispFilterMode}
          />
        );
      }
    } else if (fieldAttributeDescription === 'DOCUMENT') {
      return handleDocument();
    } else if (['PHOTO', 'MAINPHOTO', 'SKIPPABLE PHOTO'].includes(fieldAttributeDescription)) {
      return handlePhoto();
    } else if (fieldAttributeDescription === 'CALENDAR') {
      return handleCalendar();
    } else if (fieldAttributeDescription === 'TIME') {
      return handleTime();
    } else if (fieldAttributeDescription === 'MORPH PHONE' || fieldAttributeDescription === 'MORPH EMAIL') {
      return handleMorphInviteFriend(fieldAttributeDescription);
    } else if (fieldAttributeDescription === 'OTP') {
      return handleOTP();
    } else if (isRecording) {
      return (
        <AudioRecorder
        errandId={errandId}
        isRecording={isRecording}
        operatorData={operatorData}
        recipients={errandRecipients}
        setErrands={setErrands}
        setIconToShow={setIconToShow}
        setIsRecording={setIsRecording}
        setShowPermissionReminder={setShowPermissionReminder}
        resetMorph={resetMorph}
        selectedFiles={selectedFiles}
        leftOfInput={true}
        resetStates={resetStates}
        audioBlob={audioBlob}
        setAudioBlob={setAudioBlob}
        userMicOff={userMicOff}
        setUserMicOff={setUserMicOff}
        />
      );
    } else if (morphType === MorphType.Contacts) {
      return (
        <FooterIcon>
          <InviteFriendFilled />
        </FooterIcon>
      );
    } else if ([
      MorphType.ShareCustomLink, 
      MorphType.CreditRepairWelcome, 
      MorphType.RefinanceCalculatorWelcome
    ].includes(morphType)){
      return handleDefaultCloseMorph();
    } else if (fieldAttributeDescription === 'MORPH LOAN PRODUCTS') {
      return (
        <FooterIcon>
          <LeftButtonMorphLoanProducts />
        </FooterIcon>
      )
    } else if (fieldAttributeDescription === 'MORPH BORROWER SELECTOR') {
      return (
        <FooterIcon>
          <SelectAllBorrowersButton sendButtonRef={sendButtonRef}/>
        </FooterIcon>
      )
    } else if (fieldAttributeDescription && errandIcon) {
      return handleDisplayActionIcon();
    } else if (morphType === MorphType.Attachment || morphType === MorphType.PhotoMain || morphType === MorphType.PhotoPlain) {
      // Render the Paper Clip / Notepad SVG from Figma when the attachment menu is open
      return (
        <FooterIcon>
          <PaperClipNotepad />
        </FooterIcon>
      );
    } else if (isDesktop) {
      return (
        <LangPicker
          isDesktop={isDesktop}
          setDispFilterMode={setDispFilterMode}
        />
      );
    } else {
      return (
        <LanguagePickerCarousel
          isDesktop={isDesktop}
          setDispFilterMode={setDispFilterMode}
        />
      );
    }
  }, [
    errandIcon,
    fieldAttributeDescription,
    handleCalendar,
    handleDefaultCloseMorph,
    handleDisplayActionIcon,
    handleDocument,
    handleMorphInviteFriend,
    handleOTP,
    handlePhoto,
    handlePopup,
    handleTime,
    isDesktop,
    isPopupOpen,
    isRecording,
    morphType,
    newFormEvent,
    setDispFilterMode,
    t,
    wetInitial,
    wetSignature,
  ]);

  const handleCreditRepairWelcomeMessage = useCallback(() => {
    // set input field manually
    chatInputFieldRef.current?.update(t('helpMeRepairMyCredit'))
    sendButtonRef.current?.update(t('helpMeRepairMyCredit'));

    // Submit the message
    handleSubmit(null);
  }, [handleSubmit, t]);

  const handleRefinanceCalculatorWelcomeMessage = useCallback(() => {
    // Set the value of the input field
    chatInputFieldRef.current?.update(t('refinanceCalculatorWelcomeMessage'))
    sendButtonRef.current?.update(t('refinanceCalculatorWelcomeMessage'));

    // Submit the message
    handleSubmit(null);
  }, [handleSubmit, t]);

  // Function for sending the action or workflow from the use prompt list
  // Same function used in the Prompt list
  // itself when clicked, passed in from rootContext
  const sendActionWorkflow = useCallback(() => {
     if (morphType === MorphType.ShareCustomLink) {
      handleActionWorkflow(shareCustomLinkInfoRef.current._id, shareCustomLinkInfoRef.current.type, errandId)
    } else if(morphType === MorphType.UserPromptsMenu) {
      if(userSelectedPromptRef.current === null) return;
      handleActionWorkflow(userSelectedPromptRef.current?._id, userSelectedPromptRef.current?.type, errandId)
    }
    setMorphType(MorphType.None);
  }, [
    morphType,
    errandId,
    handleActionWorkflow,
    setMorphType,
    shareCustomLinkInfoRef,
    userSelectedPromptRef,
  ])

  const sendVideoMessageFromMenu = useCallback( async () => {
    await axiosCall({
      url: `message/${errandId}/video/${userSelectedVideoRef.current?.videoId}`,
      method: 'get'
    })
    setMorphType(MorphType.None)
  }, [setMorphType, errandId, userSelectedVideoRef])

  const handleContactsConsentGranted = useCallback(() => {
    // Thin Client only
    // Send contacts permission request to user with postMessage
    // As soon as permission is enabled, sync contacts
    localStorage.setItem('contactConsent', JSON.stringify(true))
    window.dispatchEvent(new StorageEvent('storage', {
      key: 'contactConsentYes',
      newValue: JSON.stringify(true),
      oldValue: localStorage.getItem('contactConsent'),
      url: window.location.href,
      storageArea: localStorage
    }));
    setShowContactsConsent(false)
  }, []);

  const openSlotMachine = useCallback(async () => {
      cancelAction(undefined, true);
      let actionRef: IUserChatAction = errandAction?.action;
      // if so, use first level action, else leave nested one.
      if (!actionRef) {
        actionRef = errandAction;
      }
      //if it has been less than a day that the userAction has been resolved, the slot machine was played.

      //As a protection we will set a localStorage played today var to determine cross-errand play history.
      //Please note that slot machine can still be abused if a user continuously clears cache.

      // Harrison 8/22/23 - Both the network calls and localStorage can be abused, BUT we intend
      // in the future to adjust the workflow to only be played by Authed users, which is why we are utilizing both methods
      if (actionRef?._id) {
        const timeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'America/Los_Angeles';
        let { preventPlay } = await shouldPreventPlaySlotMachine(errand, actionRef, timeZone)

        if (!preventPlay) {
          triggerSlotMachine(errandId, errandAction?.userActionId);
          return false;
        } else {
          return true;
        }
      }
  }, [errand, errandId, triggerSlotMachine, errandAction, cancelAction]);

  const getInputContainerStyleObj = useCallback(() => {
    let paddingTopVal;

    if(morphType && morphType !== MorphType.AngelSign) {
      if (morphType === MorphType.Wallet) {
        return { border: `3px solid ${WalletColor?.DarkBlue || '#01011A'}`,
                 borderRadius: '5px 0px 0px 5px', margin: isMobile() && '2px -1px 0px 0px' };
      } else if (morphType === MorphType.PointsTable) {
        return { border: `2px solid ${theme.palette.orange['054']}`,
                 borderRadius: `${window.innerWidth < 900 ? '0px': '5px'} 0px 0px 5px`, margin: isMobile() && '2px -1px 10px 0px' };
      } else if (morphType === MorphType.Edit) {
        paddingTopVal = 3;
      } else if (morphType === MorphType.SwipeableBoolean) {
        paddingTopVal = 10;
      } else {
        paddingTopVal = morphIndent.current?.clientHeight || 19;
      }
    } else {
      paddingTopVal = 3;
    }

    if(morphType === MorphType.UserSuggestions) {
      paddingTopVal = 7;
    }
    return {
      paddingTop: `${paddingTopVal}px`
    }
  }, [morphType, morphIndent])

  const showDownloadAppBanner = useCallback(() => {
    const TIMER_ANIMATION_BEGIN_TIME = new Date().getTime() + 2000;
    // Only show the banner on iOS and Android browser
    const user = UAParser();
    const os = user.os.name;

    // return if device is not Android or iOS or banner has been shown recently or
    // device is using thin client app or user is on the widget
    if (
      (os !== 'Android' && os !== 'iOS') ||
      ThinClientUtils.validateBannerTimeToLive() ||
      ThinClientUtils.isThinClient() ||
      isWidget
    ) {
      return;
    }

    // Calculate the time when the animation should begin:
    const currentTicks = new Date().getTime();

    const showTmID = setTimeout(() => {
      setMorphType((prev) => {
        // Only show the banner if the footer is not morphed already
        if (morphType !== MorphType.None) {
          return prev;
        }
        return MorphType.DownloadAppBanner;
      });
      const hideTmID = setTimeout(() => {
        setMorphType((prev: MorphType) => {
          if (prev === MorphType.DownloadAppBanner) {
            return MorphType.None;
          }
          return prev;
        });
        setBannerAppeared((prev) => prev + 1);
      }, TIMER_ANIMATION_BEGIN_TIME - currentTicks + 12000);
      downloadBannerTimeoutsRef.current.push(hideTmID);
    }, TIMER_ANIMATION_BEGIN_TIME - currentTicks);

    // save banner timeout ids
    downloadBannerTimeoutsRef.current.push(showTmID);
    ThinClientUtils.resetBannerTimeToLive();
  }, [isWidget, morphType, setMorphType]);

  useInitialMount(() => {
    // handle consent on load
    // Open the consent when user joins the conversation

    // handleMorphOnLoad
    // Form morphTypes do not have to be reset upon reconnecting
    if (errandType === 'form') return;
    if (rootMorphType === MorphType.None) {
      // TODO: calling setErrands onload in this function is causing a bad setState error.
      // Comenting out the setErrands fixes the error but cuases the footer to not change when the user
      // comes back to the chat. Send Action, change chats, send text as operator, navigate back, footer 
      // should not be setup to reply to the action.
      resetStates();
      // handleClosePrivateChat();
    }

    // set the random peekaboo image for russell peters
    const peekabooImages = [
      [rphead1, rphead2, rphead3, rphead4]
    ]

    const randomIndex = Math.floor(Math.random() * peekabooImages[0].length);
    setRandomPeekabooImage(peekabooImages[0][randomIndex]);
    showDownloadAppBanner();
  });

  // This useEffect watches for if we've pasted a document in the footer without going through
  // the attachment menu. Or if we've dragged a file to the footer
  useEffect(() => {
    if (selectedFilesLength > 0){
      setMorphType((prev) => {
        if (prev === MorphType.None){
          setIsMorphedFooterCloseButtonOnLeft(true);
          setIsUploadingDocs(true);
          return MorphType.Attachment
        }else{
          return prev
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles])

  // Check to see if we've launched slot machines
  useEffect(() => {
    if (!triggeredSlotMachine) return;
    cancelAction(undefined, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggeredSlotMachine])

  // Check to see if we've come for Russell Peters
  useEffect(() => {
    const russellTitle = "Relax Win"
    if (errandDisplayName !== russellTitle) return;
    const basePath = (process.env.REACT_APP_MORGAN_BASENAME || '').replace(/\/$/, '');
    const peekabooRussell = [`${basePath}/relax`, `${basePath}/relaxtshirt`]
    if (isOperator) return;
    if (tpConsentGiven && returnConsentGiven) return;
    if (peekabooRussell.includes(getWindowLocationPath())){
      setShowRPContainer(true);
      setIsRussellPeters(true);
      setShowRussellPeters(true)
      setTimeout(() => {
        setShowRussellPeters(false);
        setTimeout(() => {
          setShowRPContainer(false)
        }, 750)
      }, 3000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tpConsentGiven, isOperator, errandDisplayName, returnConsentGiven])

  useEffect(() => {
    if (!isMessagesConnected) return;

    const chatEventHandler = (payload) => {
      if (payload?.data?.type === 'cancel-action') cancelAction(undefined, true);
    }

    const socketMessage = messagesSocket.current;

    socketMessage?.on('chat-event-emitted', chatEventHandler);
    return () => {
      socketMessage?.off('chat-event-emitted', chatEventHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessagesConnected, errandId]);

  useEffect(() => {
    if(i18n.language === 'en' 
      || LanguageUtils.fetchTranslationEnabledSetting() === false 
      || isOperator) 
      return;
    // fetch current language translations for the userPromptsMenu component
    UPM_TranslationModule.processLanguage(abortController.get(), i18n.language as TLang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, isOperator])

  // This use effect checks to ensure value and in scope held
  // formatted values are in sync when changing actions that
  // utilize the same input. Using different input components
  // for password and phone-numbers for example, would remove
  // the need for this useEffect
  useEffect(() => {
    if (
      // if unformatted IS empty AND formatted is not empty.
      !chatInputFieldRef.current?.unformattedValue?.trim() &&
      chatInputFieldRef.current?.formattedValue?.trim()
    ) {
      // update value, unformatted and formatted in chatInputField component
      chatInputFieldRef.current?.update('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatInputFieldRef.current?.unformattedValue, chatInputFieldRef.current?.formattedValue]);

  /**
   * When the SpeechRecognition starts recognizing text from audio
   * it will fill the transcript buffer. Use that buffer to insert
   * the words in the message input box.
   */
  useEffect(() => {
    let currentRef = chatInputFieldRef.current;
    if (isRecording && transcript !== '') {
      currentRef?.update(`${transcript.toLowerCase()}`);
    }
    if (!isRecording) {
      resetTranscript();
    }

    return () => {
    currentRef?.update('');
      setValue('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcript, isRecording]);

  useEffect(() => {
    if (lastMessageType === "CreditRepairWelcome" && !operatorData){
      setMorphType(MorphType.CreditRepairWelcome);
    }
    if (lastMessageType === "RefinanceCalculatorWelcome" && !operatorData){
      setMorphType(MorphType.RefinanceCalculatorWelcome);
    }
    // When welcomeBack is triggered, users must provide returnConsent again
    if (lastMessageType === "Greeting" && !operatorData){
      setReturnConsentGiven(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [   
    errandLastMessageData,
    bannerAppeared
    ])

  /**
   * This useEffect runs whenever ANY of the following changes:
   * - Current Errand ActionID
   * - Current Errand Action ActionID (nested one)
   * - Current Errand Last Message Data
   * - Edit Message ID
   * - Number of Opened Screens (splitScreen etc.)
   * - Current User ID
   */
  useEffect(() => {
    // FORM type
    const chatTypeInitiator = lastMessageAction?.chatTypeInitiator;
    // check if last message type is ACTION and action chat type initiator is FORM
    if (
      lastMessageType === 'Action' &&
      chatTypeInitiator === ChatType.Form &&
      selectedIndex?.length !== 2 &&
      !justClickedCancel
    ) {
      // last userAction is form related
      // check if curr user is the one that the form action is adressed to.
      if(shouldCurrUserReplyTo(errandLastMessageData, _id) === true) {
        setMorphType(MorphType.FormOpen);
      }
    } 
    // if only FormOpen is currently active close it
    else {
      setMorphType((prev): MorphType => {
        if (prev === MorphType.FormOpen) {
          return MorphType.None;
        }
        return prev;
      });
    }
    // ALL OTHER TYPES
    
    // Main Function that fetches the field data and sets it (fieldAttribute).
    // Based on fetched fieldData, it performs some additional logic based on it's description and other values.
    async function fetchAndSetFieldAttribute(fieldData) {
      // if some field attribute is about to be set
      // AND curr motphType is UserPromptsMenu, set it to None 
      // Because user received new action.
      // ### covers case when new action is incoming BUT the userPromptsMenu morphType is in place.  
      if(morphType === MorphType.UserPromptsMenu 
        || morphType === MorphType.VideoListMenu) {
        setMorphType((prev) => MorphType.None);
      }
      //Ideally this data is not a string, and it was delivered from core as a populated field attribute.
      //TODO: track down where in UI (possibly actiondialog.tsx) we are resetting fieldAttribute data to a string, and do not do that.
      if (typeof fieldData === 'string') {
        fieldData = await axiosCall({
          url: `fieldAttribute/${fieldData}`,
        });
        setFieldAttribute(fieldData);
      } else if(fieldData) {
        setFieldAttribute(fieldData);
      }
      const fieldDataDescription = fieldData?.description;
      if (fieldDataDescription === 'CALENDAR'){
        setMorphType(MorphType.CalendarMonth)
      }
      if (fieldDataDescription === 'DOB'){
        setMorphType(MorphType.DOB)
      }
      if (fieldDataDescription === 'TIME'){
        setMorphType(MorphType.Time)
      }
      if (fieldDataDescription === ANGEL_SIGN_FIELD_ATTRIBUTE) {
        setMorphType(MorphType.AngelSign)
      }
      if (fieldDataDescription === 'MAINPHOTO'){
        //we will use the mask variable to determine the limit of the photos.
        if (fieldData.mask.length) {setPhotoLimit(Number(fieldData.mask));}
        else {setPhotoLimit(5)}
        //reset the main photo
        setPhotoSelectorIndex(null);
        setMainPhoto(null);
        setMorphType(MorphType.PhotoMain);
        textInputEnabled.current = false;
      }
      else if (fieldDataDescription === 'PHOTO' || fieldDataDescription === 'SKIPPABLE PHOTO'){
        //we will use the mask variable to determine the limit of the photos.
        if (fieldData.mask.length) {setPhotoLimit(Number(fieldData.mask));}
        else {setPhotoLimit(5)}
        setPhotoSelectorIndex(null);
        setMorphType(MorphType.PhotoPlain);
        textInputEnabled.current = false;
      }
      else if (fieldDataDescription === 'DOCUMENT'){
        setMorphType(MorphType.Attachment);
        textInputEnabled.current = false;
      }
      //the slot machine action needs the field attribte with the description 'SLOT MACHINE'
      else if (fieldDataDescription === 'SLOT MACHINE' && !getUserPlayedLast24Hours()){
        setMorphType(MorphType.SlotMachine);
      }
      else if (fieldDataDescription === 'PAYMENT'){
        //the below endpoint functions both as a check for whether
        //the payment has been made already AND as a fetch for price data
        textInputEnabled.current = false;
        const res = await axiosCall({url: `pay/field`, method: 'POST', data: { chat: errandId, context: errandActiveContext }});
        if (res.data !== 'payment already made'){
          setMorphType(MorphType.Payment);
          //set the payment component to the first step.
          setPaymentActionState(PaymentActionStateType.Preview);
        }
        else {
          setMorphType(MorphType.None);
        }
      }
      else if (fieldDataDescription === 'MORPH BORROWER SELECTOR'){
        setMorphType(MorphType.BorrowerSelector)
      }
      else if (fieldDataDescription === 'MORPH LOAN PRODUCTS') { 
        setMorphType((prev) => {
          if (prev !== MorphType.LoanProductPriceTable) {
            return MorphType.LoanProducts;
          }
          return prev;
        });
      }
      else if (fieldDataDescription === 'MORPH CREDIT REPAIR DISPUTE ACCOUNT TYPE'){
        setMorphType(MorphType.CreditRepairDisputeAccountType)
        setIsMorphedFooterCloseButtonOnLeft(false);
      } else if (fieldDataDescription === "MULTI SELECT") {
        setMorphType(MorphType.SelectMultiple);
      }
      else if (fieldDataDescription === 'PASSWORD' && 
        [
          "newPassword", 
          "newBorrowerPassword"
        ].includes(errand.action?.action?.fieldName)){
        setMorphType(MorphType.NewPassword)
      }
      else if (fieldDataDescription === 'SWIPEABLE BOOLEAN') {
        setMorphType(MorphType.SwipeableBoolean);
      }
    };

    // Edit Message Data is incomplete CHECK
    const foundEditMessage = errandMessages?.find((m) => m?._id === editMessageId);
    if (!errand // errand is null
      || (!errandAction // action is null
        && !editMessageId // edit message id is null
        && foundEditMessage?.messageType !== 'Field') // 
      ) {
      setFieldAttribute(null);
    }

    // chatTypeInitiator is either Page or Activity CHECK
    if (chatTypeInitiator === ChatType.Page 
      || chatTypeInitiator === ChatType.Activity) {
      return;
    }

    setErrands((prev) => {
      // check index and return if not found
      const chatObj = prev.find((e) => e._id === errandId);

      if (chatObj) {
        // check if current morphType is of PrivateChat type
        const isPrivate = morphType === MorphType.PrivateChat;

        // lastMessage data
        let lastMessage = chatObj.messages?.[chatObj.messages?.length - 1] as IMessage;

        // if private get the last message from privateMessages
        if (isPrivate) {
          lastMessage = chatObj.privateMessages?.[chatObj.privateMessages?.length - 1] as IMessage;
        }

        // CHECK last message action related data
        const isCurrErrandActionExists = ValidatorFunctions.isNotUndefinedNorNull(chatObj.action) === true;
        const isCurrentActionActive = chatObj.action?.active;
        const lastMessageAction = lastMessage?.action;

        // IF Errand Action Data Exists AND last message is NOT ACTION type.
        if (isCurrErrandActionExists && !isCurrentActionActive && lastMessage?.messageType !== 'Action') {
          // clear field attribute data
          setFieldAttribute(null);
        }

        /*
         * 01/04/2024 Mykyta:
         * Next check serves for the following:
         * no active actions and the latest message contains an action
         * !chatObjaction?.active gives true if action is undefiend or null.
         * Bug that happened due to this is that when user starts editing the message, it fetches the fieldAttribute
         * of the last message in chat WHICH is not the needed fieldAttribute data.
         */
        // IF Errand Action Data Exists AND Last message is ACTION type.
        else if (isCurrErrandActionExists && !isCurrentActionActive && lastMessage?.messageType === 'Action') {
          // fetch the fieldAttribute data of the Last Message
          fetchAndSetFieldAttribute(lastMessageAction?.fieldAttribute);
        }
        // if current errand action IS active.
        // get the fieldAttribute for the currently active errand action
        else if (isCurrentActionActive) {
          // if user is editing some message.
          if (editMessageId) {
            const editTargetMessageAction = chatObj.messages.find((m) => m._id === editMessageId)?.action;
            // get currently editing message fieldAttribute data
            fetchAndSetFieldAttribute(editTargetMessageAction?.fieldAttribute);
          } else {
            const currAction = chatObj.action?.action;
            // get current errand active action fieldAttribute data
            fetchAndSetFieldAttribute(currAction?.fieldAttribute);
          }
        }

        // Remember, this whole function runs After dependancies changed in this useEffect call.
        // Check if last message is ACTION and IN-PROGRESS and Current user is target user for this action.
        // ALSO check if lastMessageID did not change, then it means that this useEffect was triggered by some other change
        //  therefore, if that's the case (if prevlastMessageID is the same as current), it means that the actionID changed but not the last message,
        //  and thus, we should not update the curr action to be of the last message. (EDGE CASE FIX)
        const hasLastMessageChanged =
          prevLastMessageIdRef.current === null ||
          prevLastMessageIdRef.current === undefined ||
          prevLastMessageIdRef.current !== lastMessage?._id;
        if (
          hasLastMessageChanged === true &&
          lastMessage?.messageType === 'Action' &&
          lastMessageUserAction?.status === 'in-progress' &&
          shouldCurrUserReplyTo(lastMessage, _id)
        ) {
          // _id variable here holds current user id.
          // Set new action data as the current errand action
          chatObj.action = {
            ...lastMessage?.userAction,
            action: lastMessage?.action,
            userActionId: lastMessage?.userAction?._id,
            active: true,
          };
          chatObj.placeholder = lastMessage?.action?.description;
          chatObj.icon = lastMessage?.icon;
          chatObj.recipients = lastMessage.intendedAudience
            ? [lastMessage.sender._id, ...lastMessage.intendedAudience].sort()
            : [];
        }

        // IF curr action is NOT active.
        else if (!isCurrentActionActive) {
          ResetFooterUserAction(chatObj);
        }

        // update last message id data ref.
        prevLastMessageIdRef.current = lastMessage?._id;
      }

      return [...prev];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    errandAction?._id,
    errandLastMessageData,
    editMessageId,
    errandAction?.action?._id,
    justClickedCancel,
    selectedIndex?.length,
    _id,
    //1/25/24: Icy: Having this dependency caused infinite loop issues for morphType
    // morphType
  ]);

  useEffect(() => {
    async function getTranslatedPlaceholder(newPlaceholder, language, dispFilterMode) {
      let placeholder = dispFilterMode === 'None' ? newPlaceholder : dispFilterMode;
      let translatedPlaceholder = t(placeholder);
      if (i18n.language !== 'en') {
        if (translatedPlaceholder === placeholder && LanguageUtils.fetchTranslationEnabledSetting()) {
          try {
            translatedPlaceholder = await LanguageUtils.translateOne(placeholder, language);
          } catch (err) {
            console.error(
              `Could not translate placeholder "${placeholder}" to ${language}`,
              err
            );
            translatedPlaceholder = placeholder;
          }
        }
      }
      setPlaceholder(translatedPlaceholder);
    };
    getTranslatedPlaceholder(errandPlaceholder, i18n.language, dispFilterMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errandPlaceholder, i18n.language, dispFilterMode]);

  // on chat errand id change.
  useEffect(() => {
    // fetch from storage the new updated errand id
    const fetchedInputState = FooterInput.fetch(errandId);
    const valueToSet = fetchedInputState !== null ? fetchedInputState.value : '';

    // we have a fetched input state
    if (fetchedInputState !== null) {
      // check for address related action
      // if address was put in, in order to make it work properly, double set the footer inut value
      chatInputFieldRef.current?.update(fetchedInputState);
    }
    // no input state in storage
    else {
      chatInputFieldRef.current?.update("");
    }

    setValue(valueToSet);
    setEditMessageId('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errandId]);

  // on value change
  useEffect(() => {
    chatInputFieldRef.current?.update(value || '');
    sendButtonRef.current?.update(value || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (selectedFilesLengthRef.current === 0 && selectedFiles.length > 0) {
      selectedFilesLengthRef.current = selectedFiles.length;
      sendButtonRef.current?.startAnimation();
    } else {
      selectedFilesLengthRef.current = selectedFiles.length;
    }
    if (isRecording) {
      sendButtonRef.current?.startAnimation();
      // Use morphed footer when recording is turned on and set the topic to MorphRecording component
      setMorphType(MorphType.Recording)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles, isRecording]);

  useEffect(() => {
    if (errandAction?.action){
      if (errandAction?.action?.description === 'Slot Machine' && !getUserPlayedLast24Hours()) {
        setMorphType(MorphType.SlotMachine);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errandAction])

  // ensure that only the primary chat gets the morphed footer when creating/editing errand
  useEffect(() => {
    if (errandId === morphedId?.current) {
      setMorphType(rootMorphType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootMorphType]);

  useEffect(() => {
    if (clickedDisabledFeatures && morphType === MorphType.DownloadAppBanner) {
      setMorphType(MorphType.None);
    }
    if (!isEventsConnected) return;

    const socketEvent = eventsSocket.current;

    console.log('Events Socket - ConversationFooter - (on)');
    socketEvent?.on('show-download-app-banner', showDownloadAppBanner);

    eventBus.on('consentGiven', () => {
      // if the footer is morphed or if last message type is 
      // 'CreditRepairWelcome' or 'RefinanceCalculatorWelcome' then return
      // otherwise, show the download app banner
      if (morphType !== MorphType.None) return;
      if (["CreditRepairWelcome", "RefinanceCalculatorWelcome"].includes(lastMessageType)) return;
      if (isRussellPeters) return;
      // showDownloadAppBanner();
    });

    socketEvent.on('morph-footer', (payload) => {
      switch (payload.morphType) {
        case "pointsTable":
          setMorphType(MorphType.PointsTable);
          break;
        default:
          break;
      }
    })

    return () => {
      console.log('Events Socket - ConversationFooter - (off)');
      socketEvent?.off('show-download-app-banner', showDownloadAppBanner);
      socketEvent?.off('morph-footer');
      eventBus.remove('consentGiven');
      clearDownloadBannerTimeouts();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEventsConnected, isWidget, clickedDisabledFeatures]);

  return (
    <FooterContext.Provider
      value={{
        textInputEnabled,
        selectedContact,
        sendButtonRef,
        needToShowFormConsentRef,
        sentimentPreview,
        setSentimentPreview,
        chatInputFieldRef,
        handleOtpError,
        setHandleOtpError,
      }}
    >
      <ChatFooterModal
        action1={null}
        action2={null}
        contentText={t('permissionReminderText')}
        disableClickAway={false}
        handleAction1={null}
        handleAction2={null}
        iconType={iconToShow}
        isOpen={showPermissionReminder}
        modalType={'permissionReminder'}
        setIsOpen={setShowPermissionReminder}
        showCloseButton={true}
        title={t('permissionReminderTitle')}
      />
      <ChatFooterModal
        action1={t('no')}
        action2={t('yes')}
        contentText={t('contactsConsentText')}
        disableClickAway={false}
        handleAction1={() => setShowContactsConsent(false)}
        handleAction2={handleContactsConsentGranted}
        iconType={null}
        isOpen={showContactsConsent}
        modalType={'consent'}
        setIsOpen={setShowContactsConsent}
        showCloseButton={false}
        title={t('contactsConsentTitle')}
      />
      {isUser && errandType === ChatType.Form && (
        <FormConsentContent userConsent='form' setUserConsent={(string) => {}} />
      )}
      {messageFilter.length > 0 && (
        <Timeline
          latestDate={errandUpdatedAt}
          result={errandMessages}
          startDate={errandCreatedAt}
        />
      )}
      {/* 
        Used another context to set the selectedContact state to prevent unnecessary re-rendering.
        See section 7.3 (Preventing Context re-renders: splitting data into chunks) from: 
        https://www.developerway.com/posts/react-re-renders-guide
      */}
      <MorphContext.Provider
        value={{
          contextSetSelectedContact: setSelectedContact,
          selectedBorrowers: selectedBorrowers,
          setSelectedBorrowers: setSelectedBorrowers,
          borrowerList: borrowerList,
          setBorrowerList: setBorrowerList,
          canSendText,
          sendButtonRef,
          openSlotMachine: openSlotMachine,
          loanProducts: loanProducts,
          setLoanProducts: setLoanProducts,
          hoveredLoanProduct: hoveredLoanProduct,
          setHoveredLoanProduct: setHoveredLoanProduct,
          chosenLoanProduct,
          setChosenLoanProduct,
          msgReqStateRef,
          setFieldAttribute,
          selectedPrices,
          setSelectedPrices,
          hideCalendarMonth,
          setHideCalendarMonth,
          hideTime,
          setHideTime,
          selectedTimezoneIndex,
          setSelectedTimezoneIndex,
          setIsInvalidWorkshopDate,
          errandType: errandType,
          isSending,
          selectedFiles,
          setSelectedFiles,
          setAttachmentTabText,
          attachmentTabText,
          setIsUploadingDocs,
          isUploadingDocs
        }}
      >
        {appContext.showReloadButton && <ReloadBanner />}
        {morphType !== MorphType.None && morphType !== MorphType.AngelSign && (
          <Suspense fallback={<></>}>
            <Snackbar
              open={isWarningActive ? true : false}
              onClose={() => setIsWarningActive(null)}
              message={isWarningActive}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            />
            <MorphedConversationFooter
              filterName={filterName}
              operatorData={operatorData}
              action={Boolean(errandAction?.action)}
              parentId={errandParentId}
              handleOpenContacts={handleOpenContacts}
              setShowContactsConsent={setShowContactsConsent}
              setIconToShow={setIconToShow}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              cancelAction={cancelAction}
              handleSubmit={handleSubmit}

              // Private Chat
              editMessageId={editMessageId}
              errand={errand}
              dispFilterMode={dispFilterMode}
              setEditMessageId={setEditMessageId}
              setPreviewUrl={setPreviewUrl}
              setValue={setValue}
              showSentiment={showSentiment}
              isTyping={isTyping}
              setIsTyping={setIsTyping}

              // morph types within the array will not have the indent tab
              hasTab={![MorphType.NewPassword, MorphType.PointsTable].includes(morphType)}
            />
          </Suspense>
        )}
      <ConversationRussellStyle>
        {showRPContainer && russellPetersImg}
      </ConversationRussellStyle>

      {!isOperator && 
      !morphTypesThatHideConsent &&
      <ConsentBox 
        fieldAttributeDescription={fieldAttributeDescription}
        inputContainerRef={inputContainerRef}
      />}
      <div className='ConversationFooterRow' style={{
        display: (footerHideCondition || errandType === ChatType.Conditions) ? 'none' : 'flex', 
        flexDirection: 'row', marginBottom: morphType === MorphType.Wallet && isMobile() && 10 }}>
        <ConversationFooterStyle
            className={
              (footerHideCondition ? 'shouldHideFooter ' : '') +
              (fieldAttributeDescription === 'DROPDOWN' ? 'isDropdown ' : '')
            }
          >
            {morphType === MorphType.None && (
              <TypingIndicator
                errand={errand}
                isTyping={isTyping}
                setIsTyping={setIsTyping}
                operatorData={operatorData}
              />
            )}
            <div className={`
            ConversationFooter${morphType === MorphType.Attachment || 
              (morphType === MorphType.PhotoMain || 
              morphType === MorphType.PhotoPlain || 
              morphType === MorphType.MessageOptions || 
              morphType === MorphType.UserPromptsMenu ||
              morphType === MorphType.UserSuggestions ||
              morphType === MorphType.VideoListMenu ||
              morphType === MorphType.CreditRepairDisputeAccountType ||
              morphType === MorphType.SelectMultiple) ? ' isAttachmentMenuOpen' : ''}
              ${isMorphedFooterCloseButtonOnLeft ? ' isMorphedFooterCloseButtonOnLeft' : ''}
              ${fieldAttributeDescription === 'DROPDOWN' ? ' isDropdown' : ''}`}
              ref={conversationFooterRef}
            >
              <div
                className={`inputContainer ${(morphType === MorphType.ErrandNew || morphType === MorphType.ErrandEdit) && 'inputContainerHide'} ${(isFocused || (morphType !== MorphType.None && morphType !== MorphType.Reply && morphType !== MorphType.Edit)) ? 'focused' : ''}`}
                ref={inputContainerRef}
                style={getInputContainerStyleObj()}
              >
                {isUser && <HoneyPot honeyPot={honeyPot} setHoneyPot={setHoneyPot} />}
                {fieldAttributeDescription === 'DROPDOWN' ? (
                  <MultiSelectField
                    errand={errand}
                    ref={multiSelectFieldRef}
                    fieldAttribute={fieldAttribute}
                    handleSubmit={handleSubmit}
                    cancelAction={cancelAction}
                    msgReqStateRef={msgReqStateRef}
                    isPrivate={morphType === MorphType.PrivateChat}
                    setJustClickedCancel={setJustClickedCancel}
                  />
                ) : (
                  <form className='inputForm' onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} onSubmit={(event) => {
                    if (morphType === MorphType.PhotoMain || morphType === MorphType.PhotoPlain || morphType === MorphType.BorrowerSelector) {
                      //prevent the form behavior of loading the page.
                      //we want the icon displayed here to be the main photo selector
                      event.preventDefault();
                    } else {
                      handleSubmit(event);
                    }
                  }}>
                    {renderLeftOfInput()}
                    {/* {['SKIPPABLE', 'SKIPPABLE PHOTO'].includes(fieldAttributeDescription) && handleSkippable()} */}
                    {/* always render on desktop. IF mobile, render this only when the action is not related to password (cropped input box issue fix) */}
                    {(isDesktop === true || (isDesktop === false && isCurrActionRelatedToPassword === false)) && <CFDivider orientation='vertical' />}

                    {/* Render appropriate input box based on action type */}
                    <div className='inputFieldContainer'>
                      <FooterTypingAnimationWrapper isShown={renderFooterTypingAnimationWrapper}>
                        <TypingAnimation text={animatedText} speed={35}/>
                      </FooterTypingAnimationWrapper>
                      {
                        multipleFooterTypingAnimations !== null && <MultipleFooterTypingAnimations {...multipleFooterTypingAnimations} />
                      }
                      <ChatInputField
                        style={{ backgroundColor: 'red'}}
                        honeyPot={honeyPot}
                        cancelAction={cancelAction}
                        errand={errand}
                        fieldAttribute={fieldAttribute}
                        setFieldAttribute={setFieldAttribute}
                        handleSubmit={
                          morphType === MorphType.ShareCustomLink ? sendActionWorkflow : 
                          morphType === MorphType.VideoListMenu ? sendVideoMessageFromMenu : 
                          morphType === MorphType.CreditRepairWelcome ? handleCreditRepairWelcomeMessage : 
                          morphType === MorphType.RefinanceCalculatorWelcome ? handleRefinanceCalculatorWelcomeMessage : handleSubmit}
                        isPublic={isPublic}
                        operatorData={operatorData}
                        participantId={participant?._id}
                        placeholder={
                          morphType === MorphType.UserPromptsMenu ? getUserPromptsMenuPlaceholder()
                            : morphType === MorphType.VideoListMenu ? getVideoListMenuPlaceholder()
                              : morphType === MorphType.ShareCustomLink ? `${t("sharePlaceholder")} ${shareCustomLinkMethod === 'email' ? t("smsEmailComposerEmail") : t("smsEmailComposerSMS")}`
                                : morphType === MorphType.CreditRepairDisputeAccountType ? getCreditRepairAccountTypePlaceholder()
                                : morphType === MorphType.CreditRepairWelcome ? ''
                                : morphType === MorphType.RefinanceCalculatorWelcome ? ''
                                  : placeholder
                        }
                        selectedAddress={selectedAddress}
                        showPassword={showPassword}
                        setSelectedFiles={setSelectedFiles}
                        setFilterName={setFilterName}
                        editMessageId={editMessageId}
                      />

                      {/* Public/Internal toggle, lets operator send internal messages without user seeing */}
                      {isOperator && morphType !== MorphType.PrivateChat && (
                        <PublicInternalToggle isPublic={isPublic} setIsPublic={setIsPublic} />
                      )}

                      {/* If this is an action, show the cancel button and password visibility toggle if needed */}
                        {errandAction?.active &&
                          morphType !== MorphType.ShareCustomLink &&
                          morphType !== MorphType.Edit &&
                          morphType !== MorphType.CreditRepairDisputeAccountType && 
                          morphType !== MorphType.SwipeableBoolean &&
                          fieldAttributeDescription !== 'BOOLEAN' &&
                          fieldAttributeDescription !== 'CONFIRM' && (
                        <ActionControls
                          cancelAction={cancelAction}
                          fieldAttribute={fieldAttribute}
                          setShowPassword={setShowPassword}
                          showPassword={showPassword}
                          isPrivate={morphType === MorphType.PrivateChat}
                          setJustClickedCancel={setJustClickedCancel}
                        />
                      )}
                    </div>
                  </form>
                )}
                  {!errandAction?.active && isDesktop && morphType === MorphType.Edit && (
                        <EmojiSelector handleEmoji={handleEmoji} operatorData={operatorData} />
                  )}
                  {
                  // fieldAttributeDescription === 'BOOLEAN' ? handleBoolean() : 
                  fieldAttributeDescription === 'DROPDOWN' || 
                  fieldAttributeDescription === 'ENVELOPE' || 
                  morphType === MorphType.VideoListMenu || 
                  morphType === MorphType.UserPromptsMenu ||
                  morphType === MorphType.UserSuggestions ||
                  morphType === MorphType.CreditRepairDisputeAccountType || 
                  // morphType === MorphType.SelectMultiple || 
                  morphType === MorphType.CalendarMonth || morphType === MorphType.DOB ||
                  morphType === MorphType.Time ||
                  morphType === MorphType.Edit ||
                  morphType === MorphType.Contacts ||
                  morphType === MorphType.BorrowerSelector ||
                  morphType === MorphType.Attachment ||
                  morphType === MorphType.ShareCustomLink ||
                  morphType === MorphType.PhotoMain || 
                  morphType === MorphType.PhotoPlain ? (
                    // <ChatSendButton
                    //     disabled={morphType === MorphType.VideoListMenu || 
                    //       morphType === MorphType.UserPromptsMenu ||
                    //       morphType === MorphType.CreditRepairDisputeAccountType}
                    //     handleSubmit={
                    //       (morphType === MorphType.ShareCustomLink) ? sendActionWorkflow :
                    //       morphType === MorphType.VideoListMenu ? sendVideoMessageFromMenu : handleSubmit}                      
                    //       ref={sendButtonRef}
                    //     userActions={errandParticipants?.[0]?.userActions}
                    //     workflow={errandWorkflow}
                    //   />
                    <></>
                  ) :
                    fieldAttributeDescription === 'CONFIRM' ? handleConfirmFieldAttribute() :
                    morphType === MorphType.SwipeableBoolean ? handleSwipeableBoolean() : (
                    <>
                      {(isDesktop || [MorphType.CreditRepairWelcome].indexOf(morphType) === -1) && (<>
                      {/* {[MorphType.Contacts, MorphType.PrivateChat].indexOf(morphType) !== -1 && (
                        <Tooltip title={errandAction ? t('conversationFooterActionRequestCancel') : t('closePrivateChat')} placement='top'>
                          <IconButton
                            className="contactsCloseButton"
                            onClick={() => {
                              setMorphType((prev) => {
                                if (morphType === MorphType.Contacts) {
                                  handleCloseContacts()
                                  return MorphType.None;
                                }
                                if (errandAction) {
                                  cancelAction(undefined, true);
                                  return prev;
                                }
                                handleClosePrivateChat();
                                return MorphType.None;
                              });
                            }}
                          >
                            <HighlightOffOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      )} */}
                      {[MorphType.Reply, MorphType.Errand, MorphType.PrivateChat].indexOf(morphType) !== -1 && (
                        <Button className='closeIcon'>
                          <HighlightOffOutlinedIcon onClick={handleMorphClose} />
                        </Button>
                      )}
                      {/* {[MorphType.Reply, MorphType.PrivateChat, MorphType.AngelSign].indexOf(morphType) !== -1 && (
                        <AudioRecorder
                        errandId={errandId}
                        isRecording={isRecording}
                        operatorData={operatorData}
                        recipients={errandRecipients}
                        setErrands={setErrands}
                        setIconToShow={setIconToShow}
                        setIsRecording={setIsRecording}
                        setShowPermissionReminder={setShowPermissionReminder}
                        resetMorph={resetMorph}
                        selectedFiles={selectedFiles}
                        leftOfInput={true}
                        haveMicOn={true}
                        resetStates={resetStates}
                        />
                      )} */}
                      {!errandAction?.active && isDesktop && morphType !== MorphType.Wallet && (
                        <EmojiSelector handleEmoji={handleEmoji} operatorData={operatorData} />
                      )}
                      {!errandAction?.active &&
                      (morphType !== MorphType.Wallet) &&
                        !isWidget && (
                          <>
                            <FileSelector
                              selectedFilesLength={selectedFilesLength || 0}
                              setSelectedFiles={setSelectedFiles}
                              setIconToShow={setIconToShow}
                              setShowPermissionReminder={setShowPermissionReminder}
                              setShowContactsConsent={setShowContactsConsent}
                              handleCloseContacts={handleCloseContacts}
                              handleOpenContacts={handleOpenContacts}
                              operatorData={operatorData}
                            />
                          </>
                        )}
                      </>)}
                      
                                        </>
                  )}
                </div>
              {(
                morphType === MorphType.Attachment || 
                morphType === MorphType.MessageOptions || 
                morphType === MorphType.UserPromptsMenu ||
                morphType === MorphType.VideoListMenu ||
                morphType === MorphType.CreditRepairDisputeAccountType 
                // ||
                // morphType === MorphType.SelectMultiple
              ) && fieldAttributeDescription !== 'PHOTO' && fieldAttributeDescription !== 'SKIPPABLE PHOTO' && fieldAttributeDescription !== 'MAINPHOTO' && fieldAttributeDescription !== 'DOCUMENT' && (
                <AttachmentMenuCloseButton
                  cancelAction={cancelAction}
                  errand={errand}
                  setSelectedFiles={setSelectedFiles}
                  setIsUploadingDocs={setIsUploadingDocs}
                />
              )}
            </div>
        </ConversationFooterStyle>
        <ConversationRightOfInputStyle>
          {(operatorData || (tpConsentGiven && returnConsentGiven) || ((!tpConsentGiven || !returnConsentGiven) && !clickedDisabledFeatures)) && 
          <AudioRecorder
            errandId={errandId}
            isRecording={isRecording}
            operatorData={operatorData}
            recipients={errandRecipients}
            setErrands={setErrands}
            setIconToShow={setIconToShow}
            setIsRecording={setIsRecording}
            setShowPermissionReminder={setShowPermissionReminder}
            resetMorph={resetMorph}
            selectedFiles={selectedFiles}
            leftOfInput={false}
            fieldAttributeDescription={fieldAttributeDescription}
            errandType={errandType}
            audioBlob={audioBlob}
            setAudioBlob={setAudioBlob}
            userMicOff={userMicOff}
            setUserMicOff={setUserMicOff}
          />}

          {((!operatorData && (!tpConsentGiven || !returnConsentGiven) && clickedDisabledFeatures) ||
          fieldAttributeDescription === 'DROPDOWN' || 
           (morphType !== MorphType.None && 
           morphType !== MorphType.DownloadAppBanner &&
           morphType !== MorphType.MessageOptions &&
           morphType !== MorphType.ErrandNew &&
           morphType !== MorphType.ErrandEdit &&
           morphType !== MorphType.CreditRepairDisputeAccountType 
          //  &&
          //  morphType !== MorphType.SelectMultiple
          ) ||
            chatInputFieldRef.current?.formattedValue !== '' || selectedFiles.length > 0) && 
            fieldAttributeDescription !== 'BOOLEAN' &&
            morphType !== MorphType.SwipeableBoolean &&
          <ChatSendButton
            fieldAttributeDescription={fieldAttributeDescription}
            disabled={morphType === MorphType.VideoListMenu || 
              morphType === MorphType.UserPromptsMenu ||
              morphType === MorphType.CreditRepairDisputeAccountType}
            handleSubmit={
              (morphType === MorphType.ShareCustomLink) ? sendActionWorkflow :
              morphType === MorphType.VideoListMenu ? sendVideoMessageFromMenu : handleSubmit}                      
              ref={sendButtonRef}
            userActions={errandParticipants?.[0]?.userActions}
            workflow={errandWorkflow}
          />}
          {fieldAttributeDescription === 'BOOLEAN' && handleBoolean()}
        </ConversationRightOfInputStyle>
      </div>
      </MorphContext.Provider>
    </FooterContext.Provider>
  );
};

export default ConversationFooter;