import React, { useCallback, useMemo, useRef, useState, useEffect, PropsWithChildren, lazy, Suspense } from 'react';
import ConversationBody from './ConversationBody';
import ConversationFooter from './ConversationFooter';
import ConversationTitle from './ConversationTitle/ConversationTitle';
import Page from './Page';
import LoanConditionsViewer from './LoanConditionsViewer';
import Captcha from './Captcha';
import { Stack, Backdrop, Grow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LinkPreview from './LinkPreview';
import { styled } from '@mui/system';
import { useSocketContext } from '@contexts/socket';
import { useRootContext } from '@contexts/RootContext';
import { CustomLinkContext } from '@contexts/CustomLinkContext';
import { ErrandContext } from '@contexts/ErrandContext';
// ErrandType imports
import {
  getCurrentParticipant,
  getOperatorDisplayString,
  shouldShowInactive,
  getOperatorParticipants,
  getPrimaryParticipant,
  getNewChatPosition,
} from '@common/errandUtils';
import { morphIndentType } from '@common/MorphType';
import { IErrand } from '@interfaces/Conversation';
import { getNftData, setChatId, setNftData as setNftDataLocalStorage } from '@storage/userStorage';
import { useTranslation } from 'react-i18next';
import StyledRopeAnimation from './StyledRopeAnimation';
import ErrorBoundary from '@components/ErrorBoundary';
import axiosCall from '../Services/axios';
import { useDrop } from 'react-dnd';
import ConfirmResendWorkflowDialog from './ConfirmResendWorkflowDialog';
import {
  ElectronicSignatureEventType,
  FormBodyType,
  FormBodyScrollStateType,
  EsignSignatureType,
  FormBodySizeType,
  FormBottomButtonType,
  IElectronicSignatureState,
  PaymentActionStateType,
  EsignActionStateType,
  EsignFormType,
  FormTypeDetector,
} from '../Forms/commonForms';
import { ElectronicSignatureStateManager } from '../Forms/ElectronicSignatureStateManager';
import { MorphType } from '@common/MorphType';
import CreateSignatureMobileConversationTitle from './CreateSignatureMobileConversationTitle';
import { FormPhoneFlip2 } from '@assets/Icons';
import { ChatType } from '@common/ChatType';
import useWindowDimensions from '@common/hooks/useWindowDimensions';
import ThinClientUtils from '@common/ThinClientUtils';
import { useEventsListener } from '@common/hooks/useEventsSocket';
import { SocketListenerType, TNotificationBannerUpdate, TShowUserWallet } from '@mTypes/TSocket';
import { useLocation } from 'react-router-dom';
import Styles from '@styles/ThemeShift.module.css';
import type YouTubePlayer from 'react-player/youtube';
import { LoanProduct } from '@mTypes/TChooseLoanProducts';
import { TVideoListMenuState, VideoListMenuState } from './MorphVideoListMenu/types';
import { TUserPromptsMenuState, UserPromptsMenuState } from './MorphUserPromptsMenu/types';
import { useUserContext } from '@contexts/user';
import { ParticipantProvider } from '@contexts/participant';
import { AvatarProvider } from '@contexts/avatar';
import { TMultipleFooterTypingAnimationsState } from './FooterTypingAnimation/types';
import { AngelSignData } from '@mTypes/AngelSign';
import eventBus from '@common/eventBus';
import { TSwipeableBooleanData } from '@components/MorphSwipeableBooleans/types';


// Lazy load components
const ElectronicSignatureWrapper = lazy(() => import('./ElectronicSignatureWrapper'));
const AngelSignWrapper = lazy(() => import('./AngelSignWrapper'));

/*
 *  This component renders A morgan conversation. It is the entire big conversation box seen
 *  on the left side of the screen
 *
 *  This component has the following properties:
 *    - messages - An array of messages to be rendered by the ChatMessage component
 *    - operator - The name of the operator passed to the Conversations component
 *    - operatorView - Whether or not this component is in the operator console
 */

const StyledChatContainer: React.FC<PropsWithChildren<any>> = styled(Stack, {
  // shouldForwardProp allows props to be appended to the html if they pass the filter
  // isDekstop, isOnline, and operatorView are not valid html props so they should be filtered out.
  shouldForwardProp: (prop) =>
    prop !== 'isDesktop' &&
    prop !== 'isOnline' &&
    prop !== 'isTyping' &&
    prop !== 'operatorView' &&
    prop !== 'isCreateSignatureMobile' &&
    prop !== 'previewUrl',
})<any>(({ theme, isDesktop, isOnline, isTyping, operatorView, isCreateSignatureMobile, previewUrl }) => ({
  // theme can be accessed intrinsically here through MUI without having to pull useTheme.
  // the props can be destructured for use in conditional styles as shown below.
  height: '100%',
  width: '100%',
  justifyContent: isCreateSignatureMobile ? 'start' : 'space-between',
  boxShadow: operatorView
    ? isOnline
      ? isTyping
        ? '0px 0px 3px 3px var(--blue920)'
        : '0px 0px 3px 3px var(--peach900)'
      : '0px 0px 0px 1px var(--gray040)'
    : '0px 0px 0px 1px var(--gray040)',
  backgroundColor: theme.palette.peach['000'],
  borderRadius: isDesktop ? '8px' : '0',
  position: 'relative',
  overflow: operatorView ? 'visible' : 'hidden',
  flexDirection: isCreateSignatureMobile ? 'row' : 'column',
}));

const StyledTooltip = styled('div')({
  position: 'absolute',
  display: 'flex',
  height: '56px',
  width: '228px',
  right: '66px',
  bottom: '61px',
  backgroundColor: 'var(--orange910)',
  zIndex: '9999',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  color: 'var(--gray000)',
});

const StyledTip = styled('div')({
  position: 'relative',
  display: 'flex',
  height: '20px',
  width: '25px',
  right: '-189px',
  bottom: '-25px',
  backgroundColor: 'var(--orange910)',
  zIndex: '100',
  justifyContent: 'center',
  alignItems: 'center',
  transform: 'rotate(45deg)',
});

const ErrandTypesHandler = (props) => {
  const {
    errand,
    errandId,
    errandType,
    editMessageId,
    setEditMessageId,
    setValue,
    dispFilterMode,
    setPreviewUrl,
    showBouncyRope,
    showSentiment,
    isDesktop,
    operatorData,
  } = props;

  switch (errandType) {
    case 'undefined':
    case undefined:
      return <></>;
    case ChatType.Conditions:
      return (
        <>
          <LoanConditionsViewer errand={errand} />
        </>
      );
    case ChatType.Form:
      return (
        <Suspense fallback={<></>}>
          <ElectronicSignatureWrapper setValue={setValue} isDesktop={isDesktop} errand={errand} />
          <ConversationBody
            dispFilterMode={dispFilterMode}
            editMessageId={editMessageId}
            errand={errand}
            isPrivate={false}
            operatorData={operatorData}
            setEditMessageId={setEditMessageId}
            setPreviewUrl={setPreviewUrl}
            setValue={setValue}
            showBouncyRope={showBouncyRope}
            showSentiment={showSentiment}
          />
        </Suspense>
      );
    case ChatType.Page:
      console.log('rendering page errandType');
      return <Page link={errand?.displayName.split('<>')[1]} errandId={errandId} />;
    case ChatType.Activity:
      console.log('rendering activity errandType');
      return <></>;
    default:
      /**
       * Types: conversation, errand, team, or group.
       * Influences operator action buttons. See MessageContentWrapper.
       */
      console.log(`rendering chat of type ${errandType}`);
      return (
        <>
          {errand && (
            <ConversationBody
              dispFilterMode={dispFilterMode}
              editMessageId={editMessageId}
              errand={errand}
              isPrivate={false}
              operatorData={operatorData}
              setEditMessageId={setEditMessageId}
              setPreviewUrl={setPreviewUrl}
              setValue={setValue}
              showBouncyRope={showBouncyRope}
              showSentiment={showSentiment}
            />
          )}
        </>
      );
  }
};

const Conversation: React.FC<PropsWithChildren<any>> = (props) => {
  const {
    errand,
    operatorData,
    setSelectedIndex,
    showConfirmResendDialog,
    setShowConfirmResendDialog,
    setNotifications,
    triggerSlotMachine,
    workflow,
    notificationAlert,
    operatorView,
    showSlotMachine,
    onlineOperators,
    onlineUsers,
    operators,
    setParticipants,
  } = props;

  const {
    isWidget,
    morphedId,
    setRootMorphType,
    errands,
    setErrands,
    openedCaptcha,
    handleActionWorkflow,
    setCreateSignatureMobileIsOn,
  } = useRootContext();

  const location = useLocation();

  const errandId = useMemo(() => errand?._id, [errand?._id]);
  const errandType = useMemo(() => errand?.type, [errand?.type]);
  const errandName = useMemo(() => errand?.name, [errand?.name]);
  const errandStatus = useMemo(() => errand?.status, [errand?.status]);
  const errandMessages = useMemo(() => errand?.messages, [errand?.messages]);
  const errandRecipients = useMemo(() => errand?.recipients, [errand?.recipients]);
  const errandParticipants = useMemo(() => errand?.participants, [errand?.participants]);
  const locationStateTab = useMemo(() => location.state?.tab, [location.state?.tab]);

  const { eventsSocket, messagesSocket, isEventsConnected, isMessagesConnected } = useSocketContext();
  const { _id, isOperator, isUser } = useUserContext();

  // TODO: All of these useStates and useRefs need to be added to ErrandContext
  const [value, setValue] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const [editMessageId, setEditMessageId] = useState(null);
  const [messageFilter, setMessageFilter] = useState('');
  const [dispFilterMode, setDispFilterMode] = useState('NONE');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isMorganTyping, setIsMorganTyping] = useState(false);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [showSentiment, setShowSentiment] = useState(false);
  const [triggeredSlotMachine, setTriggeredSlotMachine] = useState(false);
  const [clickedConsentIntro, setClickedConsentIntro] = useState(false);
  const [comparedLoanProducts, setComparedLoanProducts] = useState<Array<LoanProduct>>([]);
  const [userPromptsMenuState, setUserPromptsMenuState] = useState<TUserPromptsMenuState>(
    UserPromptsMenuState.WORKFLOW_NOT_SELECTED
  );
  const [subContentPartExternalState, setSubContentPartExternalState] = useState<{
    openSignal: {
      messageId: string | null;
      eTarget: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement> | null;
    }
  }>({
    openSignal: {
      messageId: null,
      eTarget: null
    }
  });
  const morphUserPromptsMenuRef = useRef(null);
  const [videoListMenuState, setVideoListMenuState] = useState<TVideoListMenuState>(
    VideoListMenuState.VIDEO_NOT_SELECTED
  );
  const [multipleFooterTypingAnimations, setMultipleFooterTypingAnimations] =
    useState<TMultipleFooterTypingAnimationsState>(null);
  const { t } = useTranslation();
  const { isDesktop } = useWindowDimensions();
  // Flag to trigger morph wallet directly to begin NFT minting after log in
  const [bypassToMintFlag, setBypassToMintFlag] = useState(false);
  // The parameters (received by an event) used to mint/assign a user's NFT. Stored in local storage until the
  // minting/assignment is completed so that the data is not lost if the user navigates away from or refreshes
  // the page
  const [nftData, setNftData] = useState(() => {
    const nftDataLocalStorage = getNftData();
    if (nftDataLocalStorage !== null) {
      return JSON.parse(nftDataLocalStorage);
    }
    return null;
  });
  const [angelSignData, setAngelSignData] = useState<AngelSignData | null>(null); 
  const [swipeableBooleanData, setSwipeableBooleanData] = useState<TSwipeableBooleanData | null>(null);

  const theme: any = useTheme();

  // Display the tooltip for adding friends from contact list if user clicks on
  // the add-friend button on top left corner of conversation
  const [showAddFriendTooltip, setShowAddFriendTooltip] = useState(false);

  //for the photo selector, to keep track of current photo view and selected main photo.
  const [mainPhoto, setMainPhoto] = useState<number | null>(null);
  const [photoLimit, setPhotoLimit] = useState<number | null>(null);
  const [photoSelectorIndex, setPhotoSelectorIndex] = useState<number | null>(null);
  // Values for dictating to the conversation title when and what to display in the notification banner
  const [notificationBannerEnabled, setNotificationBannerEnabled] = useState(
    String(sessionStorage.getItem('notificationBannerEnabled')).toLowerCase() === 'true' ? true : false
  );
  const [notificationBannerMessage, setNotificationBannerMessage] = useState('');

  const [songPlayerData, setSongPlayerData] = useState({
    id: '',
    artist: '',
    title: '',
    thumbnail: '',
    url: '',
    pause: false,
    mute: false,
    show: false,
    closing: false,
  });

  // YouTube iframe player set to state for managing in page player
  const playerRef = useRef<YouTubePlayer>(null);
  // Controls when and how the ConversationFooter shows additional content and actions for the user.
  const [morphType, setErrandMorphType] = useState<MorphType>(MorphType.None);
  // ScrollHandler wrapping all messages, used for scrolling
  const bodyRef = useRef(null);
  // Wrapper for this entire chat, used for drag and drop / canceling edits
  const boundaryRef = useRef(null);
  // Wrapper for dynamic conversation wrapper, it wraps remaining space on errands
  const dynamicConversationWrapperRef = useRef<HTMLDivElement>(null);
  // ConversationTitle reference
  const titleContainerRef = useRef(null);
  // Boolean to allow manual rotation of esign form
  const rotationLockedRef = useRef(false);
  // The primary input field in the ConversationFooter, used for focusing
  const footerInputRef = useRef(null);
  // The ConversationFooter, used for canceling edits
  const footerRef = useRef(null);
  // An array of messages with dates, used for scrolling to specific times in the Timeline
  const searchRefs = useRef<[] | { _id: string; htmlElement: HTMLDivElement }[]>([]);
  // The bottomBox for the MorphedConversationFooter used to add padding to the top of the ConversationFooter to prevent the box from overlapping with the input.
  const morphIndent = useRef(null);
  // The QR img for user's custom link
  const [qrImgSrc, setQrImgSrc] = useState('');
  // The custom link url
  const [customLink, setCustomLink] = useState('');
  // Custom link share method
  const [shareCustomLinkMethod, setShareCustomLinkMethod] = useState('email');
  // Ref to hold share custom link info
  const shareCustomLinkInfoRef = useRef(null);

  const [welcomeUserMessageState, setWelcomeUserMessageState] = useState({
    userSuggestions: { unmountStarted: false },
    welcomeUserMessage: { inView: false, userChosenHintIndex: -1, userChosenHintIndexLockedRef: React.useRef(false) },
  });

  const [isUserWatchingLive, setIsUserWatchingLive] = useState<boolean>(false);

  const replyToRef = useRef({ chat: errandId, originalMessage: '', originalText: '' });

  const setMorphType = useCallback(
    (newContext) => {
      if (errandId === morphedId?.current) {
        setRootMorphType(newContext);
      }
      setErrandMorphType(newContext);
    },
    [morphedId, errandId, setRootMorphType]
  );

  /* Required for drag and drop action/workflows */
  // eslint-disable-next-line
  const [, drop] = useDrop(() => ({
    accept: 'userPrompt',
    drop: (item) => {
      handleActionWorkflow(item['id'], item['type'], errandId, errandRecipients);
    },
  }));

  // prevent the footer placeholder from rendering on history and live tabs
  const [esignActionState, setEsignActionState] = useState<EsignActionStateType>(EsignActionStateType.Unsigned);
  const [paymentActionState, setPaymentActionState] = useState<PaymentActionStateType>(PaymentActionStateType.Preview);
  const [formCurrentPage, setFormCurrentPage] = useState<number>(1); //used for PDFSign.tsx where there exist multiple pages
  const [formTotalPages, setFormTotalPages] = useState<number>(null); //used for PDFSign.tsx to track # of pages
  const [formBodyScrollState, setFormBodyScrollState] = useState<FormBodyScrollStateType>(
    FormBodyScrollStateType.Start
  );
  const [wetSignature, setWetSignature] = useState(undefined);
  const [wetInitial, setWetInitial] = useState(undefined);
  const [formBody, setFormBody] = useState<FormBodyType>(FormBodyType.None);
  const [formBodySize, setFormBodySizeType] = useState<FormBodySizeType>(FormBodySizeType.None);
  const [formBottomButton, setFormBottomButton] = useState<FormBottomButtonType>(FormBottomButtonType.None);
  const [mobileIndentType, setMobileIndentType] = useState<morphIndentType>(morphIndentType.FormClickToClear);
  const [messageOptionsIndex, setMessageOptionsIndex] = useState<number>(-1);
  const [isMorphedFooterCloseButtonOnLeft, setIsMorphedFooterCloseButtonOnLeft] = useState<boolean>(false);
  const messageOptionsRef = useRef<HTMLDivElement>(null);

  const signatureTypeRef = useRef<EsignSignatureType>(EsignSignatureType.Signature);
  const canvasBoundaryRef = useRef({
    [EsignSignatureType.Signature]: {
      x: {
        min: Infinity,
        max: -Infinity,
      },
      y: {
        min: Infinity,
        max: -Infinity,
      },
    },
    [EsignSignatureType.Initials]: {
      x: {
        min: Infinity,
        max: -Infinity,
      },
      y: {
        min: Infinity,
        max: -Infinity,
      },
    },
  });

  const getPrimaryParticipantFullName = useMemo(() => {
    return getPrimaryParticipant(errand);
  }, [errand]);

  const newFormEvent = useCallback(
    (event: ElectronicSignatureEventType) => {
      if (errandType !== ChatType.Form) return;

      let currentState: IElectronicSignatureState = {
        body: formBody,
        bodySize: formBodySize,
        bottomButton: formBottomButton,
        morph: morphType,
      };

      const formName = FormTypeDetector(errandName);
      currentState = ElectronicSignatureStateManager(formName, currentState, event);

      // Don't show MorphedConversationFooter on operator view
      if (isOperator) {
        currentState.morph = MorphType.None;
      }

      setFormBody(currentState.body);
      setFormBodySizeType(currentState.bodySize);
      setFormBottomButton(currentState.bottomButton);
      setMorphType(currentState.morph);
    },
    [errandName, errandType, formBody, formBodySize, formBottomButton, isOperator, morphType, setMorphType]
  );

  const shouldShowFooter = useCallback(() => {
    // chat type form should have conversationFooter
    if (errandType === ChatType.Form || errandType === ChatType.Conditions) return true;

    return (
      locationStateTab !== 'live' &&
      errandStatus !== 'closed' &&
      (locationStateTab !== 'history' || errandParticipants?.[0]?._id) &&
      errandMessages !== null
    );
  }, [errandMessages, errandParticipants, errandStatus, errandType, locationStateTab]);

  /**
   * Create chat type form handler, All form creation related logic lives in backend
   * @returns
   */
  const createFormHandler = useCallback(
    async (index: number) => {
      // if index is not defined take last message index
      if (typeof index !== 'number') {
        index = errandMessages.length - 1;
      }

      let action = errandMessages[index]?.action;
      //template id if it's aisign. otherwise that fieldname represents our old hardcoded forms.
      let formName = action?.templateId || action?.fieldName.replaceAll('_', '-');

      //for signatureConfirmationMessages in AISign, the formname will be in the message.
      if (!formName) {
        formName = JSON.parse(errandMessages[index]?.message)?.templateId;
      }

      //for AI-SIGN we're going to switch the errand if there's an already initiated form.
      if (formName && formName.includes('TEMPLATE')) {
        const errandIndex = errands.findIndex((errand) => {
          return errandName === formName;
        });

        if (errandIndex !== -1) {
          // Set selected index to the index of the found errand
          if (isDesktop) {
            setSelectedIndex((prevIndex) => [prevIndex[0], errandIndex]);
          } else {
            setSelectedIndex([errandIndex]);
          }
          return; // Stop further execution as we have set the selected index
        }
      }

      // if formName is undefined at this point that means createFormHandler function could be called from
      // signatureConfirmation message
      if (formName === undefined) {
        const signatureConfirmationMessage = JSON.parse(errandMessages[index].message);
        formName = FormTypeDetector(signatureConfirmationMessage.formName);
      }

      // errand name overwritten in chat list
      if (formName === EsignFormType.BCERTA) formName = EsignFormType.BCERTA_DESCRIPTION;

      // Prepare backend call payload
      const payload = {
        url: 'chat/create/form',
        method: 'post',
        data: {
          chatId: errandId,
          displayName: formName,
          position: getNewChatPosition(errands),
        },
      };

      try {
        const formChat = await axiosCall(payload);
        if (!formChat || !formChat._id) {
          console.error(`Conversation (Componenent).createFormHandler.error !formChat`);
          return;
        }

        setErrands((prev: IErrand[]) => {
          const foundIndex = prev.findIndex((x) => x._id === formChat._id);

          if (foundIndex === -1) {
            // if form doesn't exist in errands list ---> add it at tail
            prev.push({
              _id: formChat._id,
              name: formChat.displayName,
              isDefault: false,
              preview: formChat.preview,
              status: formChat.status,
              type: formChat.type,
              lastUpdated: formChat.updatedAt,
              icon: '',
              action: null,
              previewAllowed: true,
              participants: formChat.participants,
              lastMessageData: formChat.lastMessageData,
              position: formChat.position,
              parentId: formChat.parentId,
              color: formChat.color,
              placeholder: 'Type here...',
            } as IErrand);

            // put form on view
            if (isDesktop) {
              // desktop ---> choose second window as a added chat
              setSelectedIndex((prevIndex) => [prevIndex[0], prev.length - 1]);
            } else {
              // mobile ---> select created chat
              setSelectedIndex([prev.length - 1]);
            }

            // force re-render
            return [...prev];
          } else {
            // chat already exists -> move to already created chat
            setSelectedIndex((prevIndex) => {
              if (prevIndex.length === 2) {
                // if form is already on show don't do anything
                if (foundIndex === prevIndex[0] || foundIndex === prevIndex[1]) {
                  return prevIndex;
                }

                // change secondary conversation to form
                return [prevIndex[0], foundIndex];
              } else if (prevIndex.length === 1) {
                // if it's single screen change current conversation to form
                return [foundIndex];
              } else {
                console.info('ActionDialog setSelectedIndex has more than 2 elements?!');
              }
            });
            return prev;
          }
        });
      } catch (error) {
        console.error(`Conversation (Componenent).createFormHandler.caught`, error);
      }
    },
    [errandMessages, errandId, errands, isDesktop, setErrands, setSelectedIndex, errandName]
  );

  const handleWalletEvent = useCallback(
    async (payload?: { message: string; data: TShowUserWallet }) => {
      console.log('handleWalletEvent', payload);
      if (!payload?.data) {
        console.error('handleWalletEvent: !payload?.data');
      }
      const data = payload.data;
      setMorphType(MorphType.Wallet);
      if (data.workflow || nftData) {
        setBypassToMintFlag(true);
        if (data.nftData) {
          setNftData(data.nftData);
          setNftDataLocalStorage(JSON.stringify(data.nftData));
        }
      }
    },
    [setMorphType, nftData]
  );

  const handleNotificationBannerUpdate = useCallback(async (data?: TNotificationBannerUpdate) => {
    if (!data.enabled) {
      setNotificationBannerEnabled(false);
      setNotificationBannerMessage(null);
      sessionStorage.setItem('notificationBannerEnabled', 'clear');
      sessionStorage.removeItem('notificationBannerMessage');
    } else {
      if (
        !Boolean(sessionStorage.getItem('notificationBannerEnabled')) ||
        sessionStorage.getItem('notificationBannerEnabled') === 'clear'
      ) {
        setNotificationBannerEnabled(true);
        sessionStorage.setItem('notificationBannerEnabled', data.enabled.toString());
      }

      if (
        !sessionStorage.getItem('notificationBannerMessage') ||
        sessionStorage.getItem('notificationBannerMessage') !== data.message
      ) {
        setNotificationBannerMessage(data.message);
        sessionStorage.setItem('notificationBannerMessage', data.message);
      }
    }
  }, []);

  const closeCreateSignatureMobile = useCallback(() => {
    newFormEvent(ElectronicSignatureEventType.CloseCreateSignatureMobile);
    setCreateSignatureMobileIsOn(false);
  }, [newFormEvent, setCreateSignatureMobileIsOn]);

  useEffect(() => {
    if (!isMessagesConnected) return;

    const onChatEventEmitted = (payload) => {
      let shouldLog = false;
      if (payload.data.type === 'typing') return; // guard statement
      // 8/4/2024 - Dennis, this logic is handled in TypingIndicator.tsx
      /* if (payload?.data?.type === 'typing' && payload?.data?.chat === errandId && payload.data.operatorId !== undefined) {
        shouldLog = true;
        setMorganTyping(payload.data.message === 'true');
      }*/
      if (payload?.data?.type === 'analyzing' && payload?.data?.chat === errandId) {
        shouldLog = true;
        if (payload?.data?.message === 'true') {
          setIsAnalyzing(true);
        } else {
          setIsAnalyzing(false);
        }
      }
      if (payload?.data?.type === 'show_sentiment' && payload?.data?.chat === errandId) {
        shouldLog = true;
        setShowSentiment(true);
        setTimeout(() => {
          setShowSentiment(false);
        }, 10000); // turn off the sentiment after 10 seconds.
      }
      if (shouldLog) {
        console.log(`Messages Socket - ConversationComponent - (chat-event-emitted)`, payload);
      }
    };

    console.log(`Messages Socket - ConversationComponent - (on)`);

    const socketMessage = messagesSocket.current;

    socketMessage?.on('chat-event-emitted', onChatEventEmitted);
    return () => {
      console.log(`Messages Socket - ConversationComponent - (off)`);
      socketMessage?.off('chat-event-emitted', onChatEventEmitted);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessagesConnected, errandId]);

  useEffect(() => {
    if (!isEventsConnected) return;

    const handleNotificationBannerUpdate = (payload) => {
      const data = payload.data;

      if (!data.enabled) {
        setNotificationBannerEnabled(false);
        setNotificationBannerMessage(null);
        sessionStorage.setItem('notificationBannerEnabled', 'clear');
        sessionStorage.removeItem('notificationBannerMessage');
      } else {
        if (
          !Boolean(sessionStorage.getItem('notificationBannerEnabled')) ||
          sessionStorage.getItem('notificationBannerEnabled') === 'clear'
        ) {
          setNotificationBannerEnabled(true);
          sessionStorage.setItem('notificationBannerEnabled', data.enabled.toString());
        }

        if (
          !sessionStorage.getItem('notificationBannerMessage') ||
          sessionStorage.getItem('notificationBannerMessage') !== data.message
        ) {
          setNotificationBannerMessage(data.message);
          sessionStorage.setItem('notificationBannerMessage', data.message);
        }
      }
    };

    console.log(`Events Socket - ConversationComponent - (on)`);

    const socketEvents = eventsSocket.current;

    socketEvents?.on('notification-banner-update', handleNotificationBannerUpdate);
    return () => {
      console.log(`Events Socket - ConversationComponent - (off)`);
      socketEvents?.off('notification-banner-update', handleNotificationBannerUpdate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEventsConnected, errandId]);

  useEffect(() => {
    const { type } = errand;
    const socketMessage = messagesSocket.current;

    if (!errandId) {
      console.log(`Messages Socket - ConversationComponent - (no errand._id)`);
      return;
    }

    if (!isMessagesConnected) {
      console.log(`Messages Socket - ConversationComponent - (no messages socket)`);
      return;
    }

    // Function to join the conversation
    const joinConv = () => {
      socketMessage?.emit('join-conversation', { chatId: errandId }, (payload: any) => {
        console.log(`Messages Socket - ConversationComponent - (join-${type}) ${errandId}`, payload);
        // join-conversation sets all related notifications to read in the database.
        // this function will remove all corresponding notifications from the UI.
        setNotifications((prev) => prev.filter((x) => ![x.chat?._id, x.messageId?.chat].includes(errandId)));
        setErrands((prev) => {
          const index = prev.findIndex((e) => e?._id === errandId);

          if (index === -1) return prev;

          const toUpdate = { ...prev[index], ...payload?.data, messages: prev[index].messages };

          if (isOperator) {
            toUpdate.displayString = getOperatorDisplayString(toUpdate, operatorData);
            toUpdate.showInactive = shouldShowInactive(toUpdate, operatorData);
            toUpdate.operators = getOperatorParticipants(toUpdate, operatorData);
          }

          prev[index] = toUpdate;
          prev[index].workflow = undefined;

          return [...prev];
        });
      });
    };

    if (ThinClientUtils.isThinClient()) {
      const oldValue = localStorage.getItem('chatId');
      window.dispatchEvent(
        new StorageEvent('storage', {
          key: 'chatId',
          newValue: errandId,
          oldValue: oldValue,
          url: window.location.href,
          storageArea: localStorage,
        })
      );
      setChatId(errandId);
    }

    joinConv();

    return () => {
      if (errandId) {
        socketMessage?.emit('leave-conversation', { chatId: errandId }, (payload: any) => {
          console.log('Messages Socket - Left ' + type, errandId, payload?.message);
        });

        if (isUser) {
          setErrands((prev) => {
            const chatObj = prev.find((e) => e._id === errandId);

            if (chatObj) {
              const participant = getCurrentParticipant(chatObj, _id);

              if (chatObj.status !== 'closed' && participant) return prev;
            }

            return [...prev.filter((e) => e._id !== errandId)];
          });
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessagesConnected, errandId]);

  useEffect(() => {
    const checkSystemData = async () => {
      if (!!Boolean(sessionStorage.getItem('notificationBannerEnabled'))) {
        return;
      }

      const lenderName = theme.system.name;

      /** @type {import('../../jsdocs').SystemData} */ // @ts-ignore
      const result = await axiosCall({ url: `system?lender=${lenderName}` });
      if (result) {
        if (
          (!Boolean(sessionStorage.getItem('notificationBannerEnabled')) ||
            sessionStorage.getItem('notificationBannerEnabled') !== 'clear') &&
          !!result.notificationBannerEnabled
        ) {
          setNotificationBannerEnabled(true);
          setNotificationBannerMessage(result.message);
        }
      }
    };

    checkSystemData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const socketEvents = eventsSocket.current;

    socketEvents?.on(SocketListenerType.showUserWallet, handleWalletEvent);
    return () => {
      socketEvents?.off(SocketListenerType.showUserWallet, handleWalletEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEventsConnected, errandId, _id]);

  useEventsListener('System', SocketListenerType.notificationBannerUpdate, handleNotificationBannerUpdate);

  return (
    <ErrorBoundary debug={`./src/Components/Conversation.tsx: level 0`}>
      <CustomLinkContext.Provider
        value={{
          qrImgSrc,
          setQrImgSrc,
          customLink,
          setCustomLink,
          shareCustomLinkMethod,
          setShareCustomLinkMethod,
          shareCustomLinkInfoRef,
        }}
      >
        <ErrandContext.Provider
          value={{
            subContentPartExternalState,
            setSubContentPartExternalState,
            bodyRef,
            boundaryRef,
            titleContainerRef,
            dynamicConversationWrapperRef,
            footerInputRef,
            footerRef,
            searchRefs,
            isSearchOpen,
            isPopupOpen,
            setIsPopupOpen,
            setValue,
            morphUserPromptsMenuRef,

            multipleFooterTypingAnimations,
            setMultipleFooterTypingAnimations,

            errand: errand,
            errandId: errandId,
            errandType: errandType,

            showAddFriendTooltip,
            setShowAddFriendTooltip,
            mainPhoto,
            setMainPhoto,
            photoLimit,
            setPhotoLimit,
            photoSelectorIndex,
            setPhotoSelectorIndex,
            createFormHandler,
            morphIndent,
            signatureTypeRef,
            canvasBoundaryRef,
            esignActionState,
            setEsignActionState,
            paymentActionState,
            setPaymentActionState,
            formCurrentPage,
            setFormCurrentPage,
            formTotalPages,
            setFormTotalPages,
            formBody,
            formBodySize,
            formBottomButton,
            morphType,
            setFormBody,
            setMorphType,
            newFormEvent,
            mobileIndentType,
            setMobileIndentType,
            wetSignature,
            setWetSignature,
            wetInitial,
            setWetInitial,
            formBodyScrollState,
            setFormBodyScrollState,
            getPrimaryParticipantFullName,
            isUserWatchingLive,
            setIsUserWatchingLive,
            rotationLockedRef,
            isAnalyzing,
            setIsAnalyzing,
            isMorganTyping,
            setIsMorganTyping,
            showSentiment,
            setShowSentiment,
            editMessageId,
            setEditMessageId,
            messageOptionsRef,
            messageOptionsIndex,
            setMessageOptionsIndex,
            isMorphedFooterCloseButtonOnLeft,
            setIsMorphedFooterCloseButtonOnLeft,
            songPlayerData,
            setSongPlayerData,
            playerRef,
            replyToRef,
            comparedLoanProducts,
            setComparedLoanProducts,
            videoListMenuState,
            setVideoListMenuState,
            userPromptsMenuState,
            setUserPromptsMenuState,
            clickedConsentIntro,
            setClickedConsentIntro,
            welcomeUserMessageState,
            setWelcomeUserMessageState,
            bypassToMintFlag,
            setBypassToMintFlag,
            nftData,
            setNftData,
            messageFilter,
            setMessageFilter,
            triggeredSlotMachine,
            setTriggeredSlotMachine,
            angelSignData,
            setAngelSignData,
            swipeableBooleanData,
            setSwipeableBooleanData,
          }}
        >
          <ParticipantProvider>
          <AvatarProvider>
          <StyledChatContainer
            className="styledChatContainer"
            ref={(el) => {
              drop(el);
              boundaryRef.current = el;
            }}
            isDesktop={isDesktop}
            isOnline={
              errandParticipants?.length > 0
                ? errandParticipants.filter((x: any) => x.active && x.ai && x.userData?.status !== 'offline').length > 0
                : false
            }
            isTyping={isMorganTyping}
            operatorView={operatorView === true}
            isCreateSignatureMobile={formBody === FormBodyType.CreateSignatureMobile}
            previewUrl={previewUrl}
          >
            {showAddFriendTooltip && (
              <Grow in={showAddFriendTooltip} timeout={500}>
                <StyledTooltip>
                  <StyledTip />
                  {t('addFriendTooltip')}
                </StyledTooltip>
              </Grow>
            )}
            {openedCaptcha && (
              <Backdrop sx={{ zIndex: 1000 }} open={true} onClick={() => {}}>
                <Captcha />
              </Backdrop>
            )}
            {previewUrl?.length > 0 && (
              <LinkPreview errand={errand} url={previewUrl} setPreviewUrl={setPreviewUrl} chatId={errandId} />
            )}
            {angelSignData !== null && (
              <Suspense fallback={<></>}>
                <AngelSignWrapper
                  hashKey={angelSignData.envelopeHashkey}
                  onSuccess={(code) => {
                    console.log('AngelSign success:', code);
                    eventBus.dispatch('angelSignSuccess', { code, actionMessage: angelSignData.actionMessage });
                  }}
                  onError={(code) => {
                    console.log('AngelSign error:', code);
                    eventBus.dispatch('angelSignError', { code, actionMessage: angelSignData.actionMessage });
                  }}
                  actionMessage={angelSignData.actionMessage}
                />
              </Suspense>
            )}
            <ErrorBoundary debug={`./src/Components/Conversation.tsx: level 1`}>
              {formBody === FormBodyType.CreateSignatureMobile && window.innerWidth > window.innerHeight && (
                <CreateSignatureMobileConversationTitle />
              )}
              {formBody !== FormBodyType.CreateSignatureMobile && (
                <ConversationTitle
                  showSlotMachine={showSlotMachine}
                  errand={errand}
                  setErrands={setErrands}
                  isDesktop={isDesktop}
                  onlineOperators={onlineOperators}
                  onlineUsers={onlineUsers}
                  operatorData={operatorData}
                  operators={operators}
                  setParticipants={setParticipants}
                  isSearchOpen={isSearchOpen}
                  setIsSearchOpen={setIsSearchOpen}
                  workflow={workflow}
                  editMessageId={editMessageId}
                  notificationBannerMessage={notificationBannerMessage}
                  notificationBannerEnabled={notificationBannerEnabled}
                  setNotificationBannerEnabled={setNotificationBannerEnabled}
                />
              )}
            </ErrorBoundary>
            <Stack ref={dynamicConversationWrapperRef} minHeight="0" width="100%" sx={{ flexGrow: 1 }}>
              {/* do not play animation on widget */}
              {!isDesktop && notificationAlert && !isWidget && (
                // This is the notification alert for mobile where the
                // rope bounces out a little bit
                <StyledRopeAnimation />
              )}
              <ErrorBoundary debug={`./src/Components/Conversation.tsx: level 2`}>
                <ErrandTypesHandler
                  {...props}
                  errand={errand}
                  errandId={errandId}
                  errandType={errandType}
                  editMessageId={editMessageId}
                  setEditMessageId={setEditMessageId}
                  setValue={setValue}
                  dispFilterMode={dispFilterMode}
                  setPreviewUrl={setPreviewUrl}
                  showBouncyRope={notificationAlert}
                  showSentiment={showSentiment}
                  isDesktop={isDesktop}
                  operatorData={operatorData}
                />
              </ErrorBoundary>
            </Stack>
            <ErrorBoundary debug={`./src/Components/Conversation.tsx: level 3`}>
              <Stack
                className={morphType === MorphType.PrivateChat ? Styles.isPrivate : ''}
                ref={footerRef}
                style={{
                  position: 'static',
                  display: shouldShowFooter() ? 'flex' : 'none',
                }}
              >
                {formBody !== FormBodyType.CreateSignatureMobile && (
                  <ConversationFooter
                    dispFilterMode={dispFilterMode}
                    editMessageId={editMessageId}
                    errand={errand}
                    operatorData={operatorData}
                    setDispFilterMode={setDispFilterMode}
                    setEditMessageId={setEditMessageId}
                    setPreviewUrl={setPreviewUrl}
                    setValue={setValue}
                    showSentiment={showSentiment}
                    triggerSlotMachine={triggerSlotMachine}
                    value={value}
                  />
                )}
              </Stack>
            </ErrorBoundary>
            {/* SVG element for the curved line */}
            {formBody === FormBodyType.CreateSignatureMobile && window.innerWidth > window.innerHeight && (
              <div style={{ position: 'relative' }}>
                <svg
                  style={{
                    position: 'absolute',
                    top: '50%',
                    // transform: 'rotate(-90deg) translateX(29px)',
                    transform: 'rotate(-90deg) translate(-37.5%, -50%)',
                    transformOrigin: 'left',
                    zIndex: '1',
                  }}
                  width="104"
                  height="31"
                >
                  <path d="M 0 31 l 104 0" stroke="var(--peach000)" strokeWidth="2" />
                  <path
                    d="M 0 31 a 30 30 0 0 0 26 -15 a 30 30
                  0 0 1 52 0 a 30 30 0 0 0 26 15"
                    stroke="var(--orange700)"
                    strokeWidth="2"
                    fill="var(--peach000)"
                  />
                </svg>
                <FormPhoneFlip2
                  onClick={closeCreateSignatureMobile}
                  style={{
                    position: 'relative',
                    top: '50%',
                    transform: 'translate(-15px, -50%)',
                    zIndex: '1',
                  }}
                />
              </div>
            )}
          </StyledChatContainer>
          </AvatarProvider>
          </ParticipantProvider>
        </ErrandContext.Provider>
      </CustomLinkContext.Provider>
      {showConfirmResendDialog.open && (
        <ConfirmResendWorkflowDialog
          open={showConfirmResendDialog.open}
          onClose={() => {
            /**
             * On closing/canceling the resend dialog we want to unset the states related to
             * this specific call (ID, chat, type, recicpients), set open to false so that the
             * dialog is closed, and finally set check to true so that the next time a workflow
             * is sent we are checking for duplicates.
             */
            setShowConfirmResendDialog({
              open: false,
              chat: undefined,
              type: undefined,
              id: undefined,
              recipients: [],
              check: true,
            });
          }}
          onResend={async () => {
            /**
             * On resending we want to just recall the handle action workflow. Because the
             * sendWorkflow function now returns the showConfirmResendDialog state we do not
             * need to manually reset the state any more.
             */
            await handleActionWorkflow(
              showConfirmResendDialog.id,
              showConfirmResendDialog.type,
              showConfirmResendDialog.chat
            );
          }}
        />
      )}
    </ErrorBoundary>
  );
};
Conversation.defaultProps = {
  showSlotMachine: false,
};
export default Conversation;
