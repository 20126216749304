// The base z-index for the MorphSwipeableBooleans component
export const BASE_Z_INDEX = 999999;
// The minimum height a card can have
export const MIN_CARD_HEIGHT = 48;
// The maximum height a card can have
export const MAX_CARD_HEIGHT = 100;
// The number of items to display at a time
export const MAX_ITEMS_DISPLAYED = 3;
// Drag threshold for answer to be considered submitted on mobile
export const MOBILE_DRAG_THRESHOLD = 80;
// Drag threshold for answer to be considered submitted on desktop
export const DESKTOP_DRAG_THRESHOLD = 175;
// The amount of overlap between cards
export const CARD_OVERLAP_AMOUNT = 10;
// The name of the event that is fired when an answer is submitted
export const BOOLEAN_SWIPE_ANSWER_EVENT = 'boolean-swipe-answer';
