import React, { useMemo } from 'react';
import { IErrand, IMessage } from '@interfaces/Conversation';
import { useTranslation } from 'react-i18next';
import Sanitized from './Sanitized';
import tStyles from '@styles/Typography.module.css';
import { AccessType } from '@common/AccessType';
import { getAiOperatorIds, getUserAudience } from '@common/msgUtils';
import Styles from '@styles/MessageSender.module.css';
import ThinClientUtils from '@common/ThinClientUtils';
import { ANGEL_SIGN_FIELD_ATTRIBUTE, parseAngelSignActionMessage } from '@common/angelSignUtils';

type TMessageSenderProps = {
  errand: IErrand,
  message: IMessage,
  isEditing: boolean
}

const MessageSender = ({
  errand, message, isEditing
}: TMessageSenderProps) => {
  const { t } = useTranslation();
  const active = useMemo(() => {
    if (!errand || !Array.isArray(errand.participants)) return null;
    if (!message || !message.sender) return null;
    return errand.participants.find((p) => p && p._id && p.userData && p.userData._id === message.sender._id)?.active;
  }, [errand?._id, errand?.participants]);

  const audience = useMemo(() => {
    if (message.messageType !== 'Action' || message.operatorView) return '';
    if (!Array.isArray(message.intendedAudience)) return '';
    if (message.intendedAudience.length === 0) return '';
    const userParticipants = errand.participants.filter((p) => p?.active && p?.userType === 'User' && p.userData?.firstname);
    if (userParticipants.length === 1) return '';
    const userAudience = getUserAudience(errand.participants, message.intendedAudience);
    if (userAudience.length === userParticipants.length) return '';
    const names = userAudience.map((p) => p.userData.firstname).filter((v,i,a) => a.indexOf(v) === i);
    if (names.length === 1) return names[0];
    if (names.length === 2) return names.join(' and ');
    
    const lastElement = names.pop();
    return names.join(', ') + ', and ' + lastElement;
  }, [message?.updatedAt]);

  const baseName = useMemo(() => {
    // if there is no message sender then return an empty string so we can show Deleted User
    if (!message || !message.sender || !message.sender.firstname) return '';

    if (!message.operatorView && message.senderType === 'Operator') {
      if (message.sender.nickname) {
        const formattedNickname = (message?.sender?.nickname || '').replace(/\s+/g, '').toLowerCase();
        if (formattedNickname === 'angelai') return 'angelai';
        return message.sender.nickname;
      }

      // length is only > 1 for user to ai or user to user private chats
      if (message.accessType === AccessType.private && Array.isArray(message.intendedAudience) && message.intendedAudience.length > 1) {
        return 'angelai';
      }
    }
    // operators will see firstnames only, users will see firstname as last resort.
    return message.sender.firstname.toLowerCase();
  }, [message?.sender?.firstname, message?.operatorView, message?.senderType, message?.intendedAudience, message?.sender?.nickname]);

  const displayName = useMemo(() => {
    const name = baseName;

    if (!name) {
      return t('deletedUser');
    }

    if (name.replace(/\s+/g, '').toLowerCase() === 'angelai') {
      return 'AngelAi';
    }

    if (message.operatorView && !message.sentByCurrentUser && !active) {
      return name + ` (${t('tInactive')})`
    }

    return name;
  }, [baseName, message?.operatorView, active]);

  if (message?.messageType === 'DownloadAppMessage' && ThinClientUtils.isThinClient()) {
    return <></>;
  }

  // Don't render message sender for invalid 'ANGEL SIGN' messages
  if (
    message?.action?.fieldAttribute?.description === ANGEL_SIGN_FIELD_ATTRIBUTE &&
    !parseAngelSignActionMessage(message?.message ?? "").hashkey
  ) {
    return <></>;
  }

  return (
    <Sanitized
      className={[
        tStyles.base,
        ...(isEditing === true 
          ? [tStyles.editing]
          : [tStyles.cMessageAuthor]),
        tStyles.fwBold,
        tStyles.mbSmallest,
        tStyles.ttCapitalize,
        ...(message.alignByCurrentUser 
          ? [Styles.alignByCurrentUser] 
          : [tStyles.mrAuto]),
        ...(message?.messageType === 'Order' 
          ? [tStyles.order] 
          : []),
      ].join(' ')}
      html={`<span class="sr-only">${t('sentBy')}:</span> ${displayName}${audience ? ` → ${audience}` : ''}`}
      visible={message.visible}
      tag="p"
    />
  );
};

export default MessageSender;
