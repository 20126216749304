import React, { useEffect, useState } from 'react';
import Allign from '@styles/Allign';
import { ChatBubbleStyle } from '@styles/ChatBubbleStyle';
import { useTranslation } from 'react-i18next';
import { IMessage } from '@interfaces/Conversation';
import MessageTextStyle from '@styles/MessageTextStyle';
import { Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useUserContext } from '@contexts/user';
import LanguageUtils from '@common/LanguageUtils';

type TSwipeableBooleanMessageContentProps = {
  message: IMessage;
};

type TItemArray = { question: string; answer: string; idx: number, qidCode: string }[];

/**
 * This component renders the message sent by the user when they have completed
 * an action with the "SWIPEABLE BOOLEAN" field attribute (from the Yes/No list
 * morph type)
 */
export default function SwipeableBooleanMessageContent({ message }: TSwipeableBooleanMessageContentProps) {
  const MAX_ITEMS_UNEXPANDED = 4;
  const { i18n, t } = useTranslation();
  const { isOperator } = useUserContext();
  const [expanded, setExpanded] = useState(false);
  const [answers, setAnswers] = useState<TItemArray>([]);

  useEffect(() => {
    async function work() {
      let answersArray: TItemArray;
      try {
        answersArray = JSON.parse(message.message);
        if (!Array.isArray(answersArray)) {
          throw new Error('answersArray is not an array');
        }
        const language = LanguageUtils.fetchLocalizationLanguageSetting() || 'en';
        if (language !== 'en') {
          const translatedQuestions = await LanguageUtils.translateMany(
            answersArray.map((item) => ({ message: item.question, source: 'en' })),
            language,
            undefined
          ).catch(() => []);
          if (translatedQuestions.length) {
            answersArray = answersArray.map((item, index) => ({
              ...item,
              question: translatedQuestions[index],
            }));
          }
        }
        setAnswers(answersArray);
      } catch (err) {
        console.error(err);
        console.log(`message json: ${message.message}`);
      }
    }
    work();
  }, [message.message, i18n.language]);

  if (!message) {
    console.log('message is not defined');
    return <></>;
  }

  if (!message.message) {
    console.log('message.message is not defined');
    return <></>;
  }

  // Only show the toggle if the number of items is greater than the base
  // display limit
  const showToggle = answers.length > MAX_ITEMS_UNEXPANDED;

  return (
    <Allign
      sx={{
        marginTop: '12px',
        display: 'flex',
        width: 'fit-content',
        flexDirection: 'column',
        maxWidth: '275px',
        minWidth: '275px',
        position: 'relative',
        alignItems: message?.alignByCurrentUser ? 'flex-end' : 'flex-start',
      }}
    >
      <ChatBubbleStyle
        sx={{
          pointerEvents: 'none',
          minWidth: '100%',
          maxWidth: '100%',
          borderColor: 'var(--orange700)',
          background: 'var(--peach600)',
        }}
      >
        {/* Header */}
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '2px 0 2px 0',
            backgroundColor: 'var(--orange700)',
            color: 'white',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            pointerEvents: 'none',
            position: 'relative',
          }}
        >
          {/* Action icon */}
          {message.icon && (
            <div
              style={{
                width: '36px',
                aspectRatio: '1/1',
                backgroundColor: 'var(--orange700)',
                border: '2px solid var(--peach100)',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: 0,
                ...(isOperator
                  ? { right: 0, transform: 'translate(40%, -40%)' }
                  : { left: 0, transform: 'translate(-40%, -40%)' }),
              }}
            >
              <img
                style={{
                  height: '20px',
                  width: '20px',
                  filter: 'brightness(0) saturate(100%) invert(100%) drop-shadow(4px 3px 3px purple)',
                }}
                src={message.icon}
                alt={'Action Icon'}
              />
            </div>
          )}
          <Typography sx={{ fontWeight: 600, textAlign: 'center', width: '100%' }}>{t('Selected')}</Typography>
        </div>
        {/* Answers container */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '10px 12px 12px 0',
            width: '100%',
            gap: '12px',
            ...(!answers.length ? { minHeight: '100px' } : {}), // Loading
          }}
        >
          {/* Display answers */}
          {answers.slice(0, !expanded ? MAX_ITEMS_UNEXPANDED : undefined).map(({ question, answer, qidCode }) => (
            <div key={qidCode} style={{ display: 'flex', alignItems: 'flex-start', position: 'relative' }}>
              {/* Yes/No bubble */}
              <div
                style={{
                  position: 'absolute',
                  marginTop: '-3px',
                  left: '-12px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  width: '25px',
                  height: '25px',
                  borderRadius: '50%',
                  fontSize: '0.73rem',
                  fontWeight: 600,
                  zIndex: 1,
                  backgroundColor: answer.toLowerCase() === 'yes' ? 'var(--green850)' : 'var(--blue850)',
                }}
              >
                {answer.toLowerCase() === 'yes' ? t('yes') : t('no')}
              </div>
              {/* Question text */}
              <MessageTextStyle sx={{ paddingLeft: '20px', fontSize: '0.75rem' }}>{question}</MessageTextStyle>
            </div>
          ))}
        </div>
        {/* Read more/less toggle */}
        {showToggle && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '4px',
              minWidth: '110px',
              maxWidth: 'fit-content',
              backgroundColor: 'var(--orange700)',
              borderRadius: '9999px',
              cursor: 'pointer',
              color: 'white',
              fontWeight: 600,
              fontSize: '0.85rem',
              padding: '1px 6px 1px 6px',
              position: 'absolute',
              left: 0,
              right: 0,
              transform: 'translateY(50%)',
              margin: '0 auto',
            }}
            onClick={() => setExpanded((prev) => !prev)}
          >
            <p style={{ marginTop: '-2px' }}>{expanded ? t('readLess') : t('readMore')}</p>
            {expanded ? <ExpandLessIcon sx={{ fontSize: '1.2rem' }} /> : <ExpandMoreIcon sx={{ fontSize: '1.2rem' }} />}
          </div>
        )}
      </ChatBubbleStyle>
    </Allign>
  );
}
